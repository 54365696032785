import { getTotalHrs } from "../../../utils/durationCalculate";
import CustomAccordion from "../../common/Accordion";
import AccContent from "./AccContent";
import AccHeader from "./AccHeader";

interface Props {
  genAvailData: any;
  startDate?: string;
  endDate?: string;
  current?: boolean;
  weekNumber?: any;
  expandedId: number | null;
  onAccordionChange: (id: number) => void;
}

const CardContent: React.FC<Props> = ({
  genAvailData,
  startDate,
  endDate,
  current,
  weekNumber,
  expandedId,
  onAccordionChange,
}) => {
  return (
    <>
      <CustomAccordion
        iconClass={"acc-icon"}
        accordionClass={"acc-wrapper"}
        headClass={"acc-header"}
        contentClass={"acc-content"}
        headContent={
          <AccHeader
            startDate={startDate}
            endDate={endDate}
            averageHrs={getTotalHrs(genAvailData)}
            current={current}
            weekNumber={weekNumber}
          />
        }
        details={
          <>
            <AccContent availData={genAvailData} />
          </>
        }
        expanded={expandedId === weekNumber}
        onChange={() => onAccordionChange(weekNumber)}
      />
    </>
  );
};

export default CardContent;
