import React from "react";
import { Grid } from "@mui/material";
import PreferredCardHeader from "./PreferredCardHeader";
import PreferItem from "./PreferItem";
import { useTranslation } from "react-i18next";
import { PreferenceEntryToEdit } from "./Create";

interface Props {
  preferencesData:
    | {
        employeeScheduleConstraintId: number;
        preferredHoursPerWeek: number;
        maxHoursPerWeek: number | null;
        minHoursPerWeek: number | null;
        start: string;
        end: string;
      }
    | undefined;
  current?: boolean;
  lastPreferredDate?: string;
  editingEnabled?: boolean;
  toggleDrawer: (
    open: boolean,
    preferenceEntryToEdit?: PreferenceEntryToEdit,
  ) => void;
}

const CardContent: React.FC<Props> = ({
  preferencesData,
  current,
  editingEnabled,
  toggleDrawer,
}) => {
  const { t: translate } = useTranslation();
  return (
    <>
      <Grid container alignItems="center" tabIndex={-1}>
        <PreferredCardHeader
          preferenceEntryToEdit={{
            existingStartDate: preferencesData?.start,
            existingEndDate: preferencesData?.end,
            empConstraintId: preferencesData?.employeeScheduleConstraintId ?? 0,
          }}
          current={current}
          editingEnabled={editingEnabled}
          toggleDrawer={toggleDrawer}
        />

        <Grid
          container
          item
          textAlign={"center"}
          className="preference-item-container"
          xs={12}
          justifyContent={"space-between"}
        >
          <PreferItem
            DataItem={{
              label: translate("PREFERRED"),
              value: preferencesData?.preferredHoursPerWeek
                ? preferencesData?.preferredHoursPerWeek
                : "N/A",
            }}
          />
          <PreferItem
            DataItem={{
              label: translate("MINIMUM"),
              value: preferencesData?.minHoursPerWeek
                ? preferencesData?.minHoursPerWeek
                : "N/A",
            }}
          />
          <PreferItem
            DataItem={{
              label: translate("MAXIMUM"),
              value: preferencesData?.maxHoursPerWeek
                ? preferencesData?.maxHoursPerWeek
                : "N/A",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default CardContent;
