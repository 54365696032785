import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createContext } from "react";

/**
 * _ANALYTICS_
 * This file provides functionality related to the Microsoft Application Insights SDK for JavaScript.
 * Here you will find information and definitions for instrumenting UI analytics in our application.
 *
 * Further reading:
 * - For high-level information about Application Insights, read here: https://learn.microsoft.com/en-us/azure/azure-monitor/app/app-insights-overview
 * - The SDK was implemented following the guide provided here: https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-sdk
 * - The Application Insights SDK source code is located here: https://github.com/microsoft/ApplicationInsights-JS
 */

/**
 * AnalyticsContext provides access to the ApplicationInsight class through a React Context.
 * This context is designed to expose ApplicationInsights functionality for sending telemetry to the Azure Portal of our deployed environment.
 * See https://github.com/Microsoft/ApplicationInsights-JS?tab=readme-ov-file#sending-telemetry-to-the-azure-portal for common instrumentation methods.
 */
const AnalyticsContext = createContext<ApplicationInsights | null>(null);

// Defines the common page types, based on https://learn.microsoft.com/en-us/dynamics365/business-central/dev-itpro/developer/devenv-page-types-and-layouts
const PageType = {
  Card: "Card", // Master, reference, and set up data management.
  List: "List", // Entity overviews and navigation, and inline editing of simple entities.
  RoleCenter: "RoleCenter", // Overview of business performance and the start page for a specific user profile.
  CardPart: "CardPart", // A page that is embedded in another page, such as in a FactBox.
  ListPart: "ListPart", // A page that is embedded in another page, such as in a FactBox.
  Document: "Document", // Transaction and other document management.
  Worksheet: "Worksheet", // Line-based data entry tasks (such as journals) and inquiries.
  ListPlus: "ListPlus", // Statistics, details, and related data management.
  ConfirmationDialog: "ConfirmationDialog", // Confirmative or exceptional dialog, such as warnings.
  NavigatePage: "NavigatePage", // Multi-step dialog (also known as a "Wizard").
  StandardDialog: "StandardDialog", // Routine dialog that starts or progresses a task.
  API: "API", // Pages of this type are used to generate web service endpoints and cannot be shown in the user interface.
  ReportPreview: "ReportPreview", // Preview of a report.
  HeadlinePart: "HeadlinePart", // A page that is embedded in a RoleCenter page to display relevant insights from across the business.
  PromptDialog: "PromptDialog", // Dialog that prompts the user for input
};

export { AnalyticsContext, PageType };
