import { DialogProps, Typography } from "@mui/material";
import warningTriangleIcon from "../../../styles/assets/images/system/warning.triangle.png";
import successCheckMarkIcon from "../../../styles/assets/images/system/checkmark.circle.png";
import "./styles.scss";
import CustomBtn from "../Button";

interface Props extends DialogProps {
  title?: string;
  subTitle?: string;
  onClick: any;
  error: boolean;
  success: boolean;
  buttonText: string;
  buttonDisabled: boolean;
  showLoader: boolean;
}

const ResultMessage: React.FC<Props> = ({
  title,
  subTitle,
  onClick,
  error,
  success,
  buttonText,
  buttonDisabled,
  showLoader,
}) => {
  return (
    <div id="resultMessage">
      <div className="root">
        {error && <img src={warningTriangleIcon} className="warningIcon" />}
        {success && <img src={successCheckMarkIcon} className="warningIcon" />}
        <Typography id="title">{title}</Typography>
        {subTitle && <Typography id="subtitle">{subTitle}</Typography>}
      </div>
      <div className="button-block">
        <CustomBtn
          id="button"
          text={buttonText}
          onClick={onClick}
          disabled={buttonDisabled}
          loading={showLoader}
          circularProgressClass="progress"
        />
      </div>
    </div>
  );
};

export default ResultMessage;
