import { Grid, Stack } from "@mui/material";
import CustomDialog from "../Dialog";
import TypoGraphy from "../Typography";
import { useTranslation } from "react-i18next";

interface Props {
  openConfirmModal?: any;
  closeModal?: () => void;
  handleConfirmModal?: any;
}

const ConfirmDialog: React.FC<Props> = ({
  openConfirmModal,
  closeModal,
  handleConfirmModal,
}) => {
  const { t: translate } = useTranslation();
  return (
    <>
      <CustomDialog
        title={translate("EXIT")}
        dialogClass="confirm-dialog"
        open={openConfirmModal}
        onCancel={closeModal}
        onConfirm={() => handleConfirmModal()}
        confirmText={translate("CONFIRM")}
        cancelText={translate("CANCEL")}
        confirmBtnClass={"secondary-btn"}
        cancelBtnClass={"close-btn"}
        contentClass="dialog-content"
        isCancelIcon={true}
      >
        <Grid item xs={12}>
          <Stack>
            <TypoGraphy variant="body1">
              {translate("ALL_YOUR_INPUT_WILL_BE_LOST")}
            </TypoGraphy>
          </Stack>
        </Grid>
      </CustomDialog>
    </>
  );
};

export default ConfirmDialog;
