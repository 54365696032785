import dayjs from "dayjs";
import {
  DEFAULT_TIME_FOR_ALL_DAY_END_DATE,
  DEFAULT_TIME_FOR_ALL_DAY_POST,
} from "../components/pages/Availability/Create/Constant";
import { convertTo24HourWithSeconds } from "./durationCalculate";

export interface WeeklyDay {
  id: null | string;
  dayOfWeek: string;
  weekNumber: number;
  generalAvailabilityTimings?:
    | {
        startTimeOffset: string | null;
        endTimeOffset: string | null;
        workingHours: null | string;
        allDayAvailable?: boolean;
        notAvailable?: null;
      }[]
    | null;
}

export const returnAvailabilityToCancelPostEdit = (
  defaultWeeklyDays: any,
  updatedWeekDaysStore: any,
  comment: any,
  selectedStartDate: any,
  selectedEndDate: any,
  selectedRequestId: any,
  cancelAvailability: boolean,
  editPendingAvailability: boolean,
  editPendingAvailabilityData: any,
) => {
  const fullWeeklyDays: WeeklyDay[] = defaultWeeklyDays?.map((day: any) => ({
    id: null,
    weekNumber: 1,
    dayOfWeek: day.dayOfWeek,
    generalAvailabilityTimings: [],
  }));

  updatedWeekDaysStore?.forEach((selectedDay: any) => {
    const index = fullWeeklyDays?.findIndex(
      (day) => day?.dayOfWeek === selectedDay?.dayOfWeek,
    );
    if (index !== -1) {
      fullWeeklyDays[index] = { ...fullWeeklyDays[index], ...selectedDay };
    }
  });

  const commentArray = comment !== "" ? [{ comment: comment }] : [];
  const postGeneralAvailability = fullWeeklyDays?.map((day) => {
    let timings: any[] = [];
    if (day.generalAvailabilityTimings) {
      timings = day.generalAvailabilityTimings?.map((timing) => ({
        dayOfWeek: day.dayOfWeek,
        weekNumber: day.weekNumber,
        generalAvailabilityTimings: [
          {
            startTimeOffset:
              timing?.allDayAvailable ?? !timing.startTimeOffset
                ? DEFAULT_TIME_FOR_ALL_DAY_END_DATE
                : convertTo24HourWithSeconds(timing.startTimeOffset),
            endTimeOffset:
              timing?.allDayAvailable ?? !timing.endTimeOffset
                ? DEFAULT_TIME_FOR_ALL_DAY_POST
                : convertTo24HourWithSeconds(timing.endTimeOffset),
            allDayAvailable: timing?.allDayAvailable,
          },
        ],
      }));
    }
    return timings;
  });
  const StartDateFormatted = dayjs(selectedStartDate)?.format("YYYY-MM-DD");
  const EndDateFormatted = selectedEndDate
    ? dayjs(selectedEndDate)?.format("YYYY-MM-DD")
    : selectedEndDate;

  const availabilityDataToBeCancelled = {
    status: "Cancelled",
    id: selectedRequestId,
    comments: commentArray,
    effectiveFrom: StartDateFormatted,
    endsAfter: EndDateFormatted,
    generalAvailability: postGeneralAvailability.flat(),
  };
  const postNewAvailability = {
    status: "Requested",
    comments: commentArray,
    effectiveFrom: StartDateFormatted,
    endsAfter: EndDateFormatted,
    generalAvailability: postGeneralAvailability.flat(),
  };
  const editPendingAvailabilityPutData = {
    status: "Requested",
    id: editPendingAvailabilityData.id,
    comments: commentArray,
    effectiveFrom: StartDateFormatted,
    endsAfter: EndDateFormatted,
    generalAvailability: postGeneralAvailability.flat(),
  };
  if (cancelAvailability) {
    return availabilityDataToBeCancelled;
  }
  if (editPendingAvailability) {
    return editPendingAvailabilityPutData;
  } else if (!cancelAvailability && !editPendingAvailability) {
    return postNewAvailability;
  }

  return availabilityDataToBeCancelled;
};
