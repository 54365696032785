import { handleFetchError } from "../../utils/errorHandler";

interface HttpClient {
  get(url: string): Promise<Response>;
  post(url: string, data: any): Promise<Response>;
}

class AvailabilityService {
  private httpClient: HttpClient;
  private url: string;

  constructor(httpClient: HttpClient) {
    // const partnerId = localStorage.getItem('userEmployeeId');
    this.httpClient = httpClient;
    this.url = `/partner/availability`;
  }

  async getData(param: any): Promise<any> {
    try {
      const response = await this.httpClient.get(
        this.url + `?partnerId=${param}`,
      );

      if (response.status === 200) {
        return response.json();
      } else {
        return response;
      }
    } catch (error: any) {
      const errorMessage = handleFetchError(error);
      console.error(`API Error: ${errorMessage}`);
    }
  }

  async createData(el: any): Promise<any> {
    try {
      const response = await this.httpClient.post(this.url, el);

      if (response.status === 200) {
        return response.json();
      } else {
        return response;
      }
    } catch (error: any) {
      const errorMessage = handleFetchError(error);
      console.error("API Error:", errorMessage);
    }
  }
}

export default AvailabilityService;
