import { handleFetchError } from "../../utils/errorHandler";

interface HttpClient {
  get(url: string): Promise<Response>;
  post(url: string, data: any): Promise<Response>;
  // This is a temporary change. post will be the final method after all the API changes are completed.
  postAvailability(url: string, data: any): Promise<Response>;
  updateAvailability(url: string, data: any): Promise<Response>;
  update(url: string, data?: any): Promise<Response>;
}

class AvailabilityRequestService {
  private httpClient: HttpClient;
  private url: string;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
    this.url = `/partner/availability-requests`;
  }

  async getData(param: any): Promise<any> {
    try {
      const response = await this.httpClient.get(
        this.url + `?partnerId=${param}`,
      );

      if (response.status === 200) {
        return response.json();
      } else {
        return response;
      }
    } catch (error: any) {
      const errorMessage = handleFetchError(error);
      console.error("API Error:", errorMessage);
    }
  }

  async createAvailabilityData(el: any): Promise<any> {
    try {
      const userEmployeeId = localStorage.getItem("userEmployeeId");
      // This is a temporary change. post will be the final method after all the API changes are completed.
      // postAvailability method points to v2 URL and post method below points to v1 URL.
      // The v2 change is temporary and will be tested in DEV and ST until all API changes are completed after which it will all point to v2.
      // Further changes will be completed with Edit functionality - TWP-50377
      const response: any = await this.httpClient.postAvailability(
        `/partner/availability-request?partnerId=${userEmployeeId}`,
        el,
      );
      if (response && response.errorResponse === null) {
        return response;
      } else if (response.errorResponse !== null) {
        const errorMessage = handleFetchError(response);
        console.error("API Error:", errorMessage);
        return response.errorResponse.status;
      }
    } catch (error: any) {
      const errorMessage = handleFetchError(error);
      console.error("API Error:", errorMessage);
    }
  }

  async deleteAvailabilityData(el: any): Promise<any> {
    try {
      const userEmployeeId = localStorage.getItem("userEmployeeId");
      const response: any = await this.httpClient.updateAvailability(
        `/partner/availability-request?partnerId=${userEmployeeId}`,
        el,
      );
      if (response && response.errorResponse === null) {
        return response;
      } else if (response.errorResponse !== null) {
        const errorMessage = handleFetchError(response);
        console.error("API Error:", errorMessage);
        return response.errorResponse.status;
      }
    } catch (error: any) {
      const errorMessage = handleFetchError(error);
      console.error("API Error:", errorMessage);
    }
  }

  async updateAvailabilityData(el: any): Promise<any> {
    const partnerId = localStorage.getItem("userEmployeeId");
    try {
      const response: any = await this.httpClient.updateAvailability(
        `/partner/availability-request?partnerId=${partnerId}`,
        el,
      );
      if (response && response.errorResponse === null) {
        return response;
      } else if (response.errorResponse !== null) {
        console.error("API Error:", response.errorResponse.errorMessage);
        return response;
      }
    } catch (error: any) {
      const errorMessage = handleFetchError(error);
      console.error("API Error:", errorMessage);
    }
  }
}

export default AvailabilityRequestService;
