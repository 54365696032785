import { Stack } from "@mui/material";
import TypoGraphy from "../../../common/Typography";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { MAX_CHARACTERS_ALLOWED_IN_AVAILABILITY_COMMENT } from "../../../GlobalConstants";

interface Props {
  comment: string;
  setComment: (comment: string) => void;
  updateComment: (comment: string) => void;
}

const AddComment: React.FC<Props> = ({
  comment,
  setComment,
  updateComment,
}) => {
  const { t: translate } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocusBlur = (focused: boolean) => {
    setIsFocused(focused);
  };
  const handleComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (comment?.length <= 255) {
      setComment(event?.target?.value);
      updateComment(event?.target?.value); // Call the updateComment function
    }
  };

  const stopComment = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (comment?.length >= 255 && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  return (
    <Stack className={"comment"}>
      <Stack className={"optional"}>
        <TypoGraphy variant="body1" typeClass="light-font">
          {`(${translate("OPTIONAL")})`}
        </TypoGraphy>
      </Stack>
      <Stack className="characters-left">
        <TypoGraphy variant="body1" typeClass="regular-font">
          {comment?.length === 0
            ? `255 ${translate("CHARACTERS")}`
            : `${255 - comment?.length} ${translate("CHARACTERS_LEFT")}`}
        </TypoGraphy>
      </Stack>
      <Stack className="comment-box">
        <textarea
          placeholder={
            !isFocused ? translate("ADD_A_COMMENT_FOR_YOUR_MANAGER") : ""
          }
          maxLength={MAX_CHARACTERS_ALLOWED_IN_AVAILABILITY_COMMENT}
          name="comment"
          rows={6}
          onKeyDown={(e) => {
            stopComment(e);
          }}
          className="text-area"
          value={comment}
          onChange={handleComment}
          onFocus={() => handleFocusBlur(true)}
          onBlur={() => handleFocusBlur(false)}
        />
      </Stack>
    </Stack>
  );
};

export default AddComment;
