import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "../../services/commonServices/apiService";
import AvailabilityAllService from "../../services/availabilityAll";
const AvailService: AvailabilityAllService = new AvailabilityAllService(
  ApiService,
);
export const getAvailabilityAll = createAsyncThunk(
  `availabilityAll/getData`,
  async () => {
    const userEmployeeId = localStorage.getItem("userEmployeeId");
    const res = await AvailService.getData(userEmployeeId);
    return res;
  },
);
