export const defaultWeeklyDays = [
  {
    dayOfWeek: "MONDAY",
    generalAvailabilityTimings: [
      {
        startTimeOffset: null,
        endTimeOffset: null,
        allAvailable: null,
        notAvailable: null,
        workingHours: null,
      },
    ],
  },
  {
    dayOfWeek: "TUESDAY",
    generalAvailabilityTimings: [
      {
        startTimeOffset: null,
        endTimeOffset: null,
        allAvailable: null,
        notAvailable: null,
        workingHours: null,
      },
    ],
  },
  {
    dayOfWeek: "WEDNESDAY",
    generalAvailabilityTimings: [
      {
        startTimeOffset: null,
        endTimeOffset: null,
        allAvailable: null,
        notAvailable: null,
        workingHours: null,
      },
    ],
  },
  {
    dayOfWeek: "THURSDAY",
    generalAvailabilityTimings: [
      {
        startTimeOffset: null,
        endTimeOffset: null,
        allAvailable: null,
        notAvailable: null,
        workingHours: null,
      },
    ],
  },
  {
    dayOfWeek: "FRIDAY",
    generalAvailabilityTimings: [
      {
        startTimeOffset: null,
        endTimeOffset: null,
        allAvailable: null,
        notAvailable: null,
        workingHours: null,
      },
    ],
  },
  {
    dayOfWeek: "SATURDAY",
    generalAvailabilityTimings: [
      {
        startTimeOffset: null,
        endTimeOffset: null,
        allAvailable: null,
        notAvailable: null,
        workingHours: null,
      },
    ],
  },
  {
    dayOfWeek: "SUNDAY",
    generalAvailabilityTimings: [
      {
        startTimeOffset: null,
        endTimeOffset: null,
        allAvailable: null,
        notAvailable: null,
        workingHours: null,
      },
    ],
  },
];

export const getDayOfWeekNumber = (dayOfWeek: string): number => {
  switch (dayOfWeek) {
    case "MONDAY":
      return 1;
    case "TUESDAY":
      return 2;
    case "WEDNESDAY":
      return 3;
    case "THURSDAY":
      return 4;
    case "FRIDAY":
      return 5;
    case "SATURDAY":
      return 6;
    case "SUNDAY":
      return 0;
    default:
      return -1;
  }
};

export const START_TIME_OPTIONS: string[] = [];
export const END_TIME_OPTIONS: string[] = [];

for (let hour = 0; hour < 24; hour++) {
  for (let minute = 0; minute < 60; minute += 15) {
    const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour; // Convert 0 to 12 and adjust for PM hours
    const period = hour < 12 ? "AM" : "PM";
    const formattedMinute = minute.toString().padStart(2, "0");
    if (!(formattedHour === 0 && formattedMinute === "00" && period === "AM")) {
      // Exclude 12:00 AM
      START_TIME_OPTIONS.push(`${formattedHour}:${formattedMinute} ${period}`);
      END_TIME_OPTIONS.push(`${formattedHour}:${formattedMinute} ${period}`);
    }
  }
}
const startTime = END_TIME_OPTIONS.shift();
if (startTime) {
  END_TIME_OPTIONS.push(startTime);
}

export const DEFAULT_TIME = "00:00 AM";
export const DEFAULT_TIME_FOR_ALL_DAY = "1.0:00";
export const DEFAULT_HOURS_FOR_ALL_DAY = "24:00";
export const LAST_SELECTABLE_START_TIME = "11:45 PM";
export const LAST_SELECTABLE_END_TIME = "12:00 AM";
export const START_TIME = "12:00 AM";
export const START_TIME_NOON = "11:45 AM";
export const END_TIME_NOON = "12:00 PM";
export const DEFAULT_TIME_FOR_ALL_DAY_POST = "00:00:00";
export const DEFAULT_TIME_FOR_ALL_DAY_END_DATE = "00:00:00";
