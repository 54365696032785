import { Checkbox, Grid, Stack } from "@mui/material";
import TypoGraphy from "../../../common/Typography";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/store";

interface Props {
  handleCopyRequest: (checked: boolean) => void;
  totalHours: any;
}

const CopyRequest: React.FC<Props> = ({ handleCopyRequest, totalHours }) => {
  const { t: translate } = useTranslation();
  const { availabilityData } = useAppSelector(
    (state: any) => state.availabilityAll,
  );
  const currentAvailabilityData =
    availabilityData?.currentAvailability?.weeklyAvailabilityResponseList?.[0]
      ?.generalAvailability;
  const copyCurrentAvailability = useAppSelector(
    (state) => state.copyCurrentAvailability.copyCurrentAvailability,
  );

  return (
    <Grid container xs={12} item alignItems={"center"} className="copy-data">
      <Grid xs={9} item>
        {currentAvailabilityData && (
          <Stack direction={"row"} alignItems="center" className="check-item">
            <Checkbox
              checked={copyCurrentAvailability}
              onChange={(e) => handleCopyRequest(e?.target?.checked)}
              inputProps={{
                "aria-label": `${translate("COPY_CURRENT_AVAILABILITY")}`,
              }}
            />
            <TypoGraphy variant="body1" typeClass={"regular-font"}>
              {translate("COPY_CURRENT_AVAILABILITY")}
            </TypoGraphy>
          </Stack>
        )}
      </Grid>

      <Grid xs={3} item textAlign={"right"}>
        <TypoGraphy variant="body1" typeClass={"avg-hours"}>
          {totalHours && totalHours === "0:0" ? "0" : totalHours}{" "}
          {translate("HRS")}/{translate("WEEK")}
        </TypoGraphy>
      </Grid>
    </Grid>
  );
};

export default CopyRequest;
