import React, { useContext, useEffect, useState, useRef } from "react";
import MainCard from "../../common/Card";
import {
  Avatar,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import CustomIcon from "../../common/Icon";
import { Person, Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import TypoGraphy from "../../common/Typography";
import CustomBtn from "../../common/Button";
import { BUTTON, LOCAL_STORAGE_LANGUAGE } from "../../GlobalConstants";
import "./styles.scss";
import LanguageModal from "./LanguageModal";
import Loader from "../Loader";
import { AuthContext } from "react-oauth2-code-pkce";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
import { handleLocalError } from "../../../utils/errorHandler";
import { AnalyticsContext, PageType } from "../../../analytics";

const UserProfile: React.FC = () => {
  const [openLangModal, setOpenLangModal] = useState<boolean>(false);
  const [radioFocused, setRadioFocused] = useState<boolean>(false);
  const { storeData } = useAppSelector((state: any) => state.userStoreData);
  const [newLanguage, setNewLanguage] = useState<string | null>(null);
  const { t: translate, i18n } = useTranslation();
  const { logOut } = useContext(AuthContext);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const analytics = useContext(AnalyticsContext);

  useEffect(
    () =>
      analytics?.trackPageView({
        name: "User Profile",
        pageType: PageType.Document,
      }),
    [],
  );

  const logoutUrl = `${process.env.REACT_APP_LOGOUT_REDIRECT_URI}`;
  const redirectUrl = `${process.env.REACT_APP_REDIRECT_URI}`;

  useEffect(() => {
    if (cancelRef.current) {
      cancelRef.current.focus();
    }
  }, [openLangModal]);

  const closeModal = () => {
    setOpenLangModal(!openLangModal);
  };

  const handleLanguageChange = () => {
    if (newLanguage === null) return;
    i18n
      .changeLanguage(newLanguage)
      .then(() => {
        localStorage.setItem(LOCAL_STORAGE_LANGUAGE, newLanguage);
        closeModal();
      })
      .catch((e: any) => {
        analytics?.trackEvent({
          name: `Error: Failed to change language`,
          properties: {
            page: "User Profile",
            workflow: "Language change",
            component: "UserProfile",
            error: e,
          },
        });
        handleLocalError("handleLanguageChange", e);
      });
  };

  const handleLogout = () => {
    localStorage.removeItem("userEmployeeId");
    if (!(process.env.REACT_APP_IS_SSO_ENABLED === "true")) {
      navigate("/");
      window.location.reload();
    } else {
      window.location.href = logoutUrl;
      navigate("/");
      if (location.pathname === redirectUrl) {
        window.location.href = logoutUrl;
      }
    }
    logOut();
    localStorage.clear();
    sessionStorage.clear();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      navigate("/");
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <Grid
        className="profile-dialog"
        aria-label={translate("USER_PROFILE_DIALOG")}
      >
        <Grid
          item
          container
          className="dialog-header"
          justifyContent={"flex-end"}
        >
          <Grid item xs={6}>
            <Stack className="close-icon">
              <IconButton
                onClick={() => {
                  analytics?.trackEvent({
                    name: `Close button click`,
                    properties: {
                      page: "User Profile",
                      workflow: "View",
                      component: "Close button",
                    },
                  });
                  navigate("/");
                }}
                aria-label={translate("CLOSE_LANGUAGE_SELECTION")}
                tabIndex={0}
                role={BUTTON}
                ref={cancelRef}
              >
                <Close />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={12} className="dialog-content" alignContent={"center"}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Avatar className="profile-icon">
                <CustomIcon IconComponent={Person} iconClass="user-icon" />
              </Avatar>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <MainCard cardClass={"profile-card"} contentClass={"card-content"}>
              <TypoGraphy variant="h1" component={"h1"}>
                {storeData.firstName ? (
                  storeData.firstName
                ) : (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Loader />
                  </Stack>
                )}
              </TypoGraphy>
            </MainCard>
          </Grid>

          <Grid item xs={12}>
            <MainCard
              cardClass={"profile-card"}
              headClass={"card-header"}
              contentClass={"language-content"}
              title={translate("LANGUAGE_SELECTION")}
            >
              <FormControl>
                <RadioGroup>
                  {i18n.languages.map((lang: string) =>
                    i18n.language !== lang ? (
                      <FormControlLabel
                        value={lang}
                        key={lang}
                        control={
                          <Radio
                            onFocus={() => {
                              setRadioFocused(!radioFocused);
                            }}
                            value={i18n.language}
                            onClick={() => {
                              analytics?.trackEvent({
                                name: `Language selected: ${lang}`,
                                properties: {
                                  page: "User Profile",
                                  workflow: "View",
                                  component: "Language select",
                                },
                              });
                              setNewLanguage(lang);
                              setOpenLangModal(true);
                              setRadioFocused(false);
                            }}
                            checked={radioFocused}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                const selectedRadio = event.target;
                                if (
                                  selectedRadio instanceof HTMLInputElement &&
                                  selectedRadio.type === "radio"
                                ) {
                                  analytics?.trackEvent({
                                    name: `Language selected: ${lang}`,
                                    properties: {
                                      page: "User Profile",
                                      workflow: "View",
                                      component: "Language select",
                                    },
                                  });
                                  setOpenLangModal(true);
                                  setNewLanguage(lang);
                                  setRadioFocused(false);
                                }
                              }
                            }}
                          />
                        }
                        label={translate(`${lang.toUpperCase()}_LABEL`)}
                        aria-label={translate(
                          `UPDATE_${lang.toUpperCase()}_RADIO_BUTTON`,
                        )}
                      />
                    ) : null,
                  )}
                </RadioGroup>
              </FormControl>
            </MainCard>
          </Grid>

          <Grid item xs={12}>
            <CustomBtn
              text={translate("LOGOUT")}
              onClick={() => {
                analytics?.trackEvent({
                  name: `Logout button click`,
                  properties: {
                    page: "User Profile",
                    workflow: "View",
                    component: "Logout button",
                  },
                });
                handleLogout();
              }}
              className="logout-btn secondary-btn"
              role={BUTTON}
            />
          </Grid>
          <Grid item xs={12} className="profile-bg"></Grid>
        </Grid>
      </Grid>

      {openLangModal && (
        <LanguageModal
          openLangModal={openLangModal}
          closeModal={closeModal}
          activeLang={i18n.language}
          languageOptions={i18n.languages}
          handleLanguageChange={handleLanguageChange}
        />
      )}
    </>
  );
};

export default UserProfile;
