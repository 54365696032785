import { handleFetchError } from "../../utils/errorHandler";

interface ErrorResponseType {
  status: number;
  errorMessage: string;
}

interface ResponseType {
  errorResponse: ErrorResponseType;
  data: any;
}

interface HttpClient {
  get(url: string): Promise<Response>;
  post(url: string, data: any): Promise<ResponseType>;
  update(url: string, data: any): Promise<ResponseType>;
}

class PreferencesService {
  private httpClient: HttpClient;
  private url: string;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
    this.url = `/partner/preferred-hours`;
  }

  async getData(): Promise<any> {
    const partnerId = localStorage.getItem("userEmployeeId");
    try {
      const response: any = await this.httpClient.get(
        this.url + `?partnerId=${partnerId}`,
      );
      if (response && response.errorResponse === null) {
        return response;
      } else if (response.errorResponse !== null) {
        return response.errorResponse.status;
      }
    } catch (error: any) {
      const errorMessage = handleFetchError(error);
      console.error("API Error:", errorMessage);
    }
  }

  async getAttributeData(): Promise<any> {
    const partnerId = localStorage.getItem("userEmployeeId");
    try {
      const response: any = await this.httpClient.get(
        `/partner/employee/attributeResponse?partnerId=${partnerId}`,
      );
      if (response && response.errorResponse === null) {
        return response;
      } else if (response.errorResponse !== null) {
        const errorMessage = handleFetchError(response);
        console.error("API Error:", errorMessage);
        return response.errorResponse.status;
      }
    } catch (error: any) {
      const errorMessage = handleFetchError(error);
      console.error("API Error:", errorMessage);
    }
  }

  async createData(el: any): Promise<any> {
    const partnerId = localStorage.getItem("userEmployeeId");
    try {
      const response: ResponseType = await this.httpClient.post(
        `/partner/preferred-hours?partnerId=${partnerId}`,
        el,
      );
      if (response && response.errorResponse === null) {
        return response;
      } else if (response.errorResponse !== null) {
        console.error("API Error:", response.errorResponse.errorMessage);
        return response;
      }
    } catch (error: any) {
      const errorMessage = handleFetchError(error);
      console.error("API Error:", errorMessage);
    }
  }

  async updateData(el: any): Promise<any> {
    const partnerId = localStorage.getItem("userEmployeeId");
    try {
      const response: ResponseType = await this.httpClient.update(
        `/partner/preferred-hours?partnerId=${partnerId}`,
        el,
      );
      if (response && response.errorResponse === null) {
        return response;
      } else if (response.errorResponse !== null) {
        console.error("API Error:", response.errorResponse.errorMessage);
        return response;
      }
    } catch (error: any) {
      const errorMessage = handleFetchError(error);
      console.error("API Error:", errorMessage);
    }
  }
}

export default PreferencesService;
