import React from "react";
import { Grid, Stack } from "@mui/material";
import TypoGraphy from "../../common/Typography";
import { AccessTime } from "@mui/icons-material";
import CustomIcon from "../../common/Icon";
import { useTranslation } from "react-i18next";
import { getMonthTranslations } from "../../../utils/getDays";
import { useAppSelector } from "../../../redux/store";
import { useEffect, useState } from "react";
import weekLink from "../../../styles/assets/svg/weekLink.svg";

interface Props {
  startDate?: string;
  averageHrs?: number | string;
  weekNumber?: number;
  current?: boolean;
}

const KickOffHeader: React.FC<Props> = ({
  startDate,
  averageHrs,
  weekNumber,
  current,
}) => {
  const { t: translate } = useTranslation();
  const { remainingDays } = getMonthTranslations();

  const { availabilityData } = useAppSelector(
    (state: any) => state.availabilityAll,
  );
  const [currentData, setCurrentData] = useState<any>();

  useEffect(() => {
    if (availabilityData) {
      setCurrentData(
        availabilityData?.currentAvailability?.weeklyAvailabilityResponseList,
      );
    }
  }, [availabilityData]);

  return (
    <>
      <Grid xs={8} item>
        {current ? (
          <Stack alignItems={"center"} direction={"row"}>
            {currentData && currentData?.length > 1 ? (
              <img className="MuiSvgIcon-root" src={weekLink} alt={weekLink} />
            ) : null}

            <TypoGraphy variant="body1" typeClass={"semi-bold-font leftDays"}>
              {currentData && currentData?.length > 1
                ? `${translate("AVAILABLE_WEEK")} ${weekNumber}`
                : null}
            </TypoGraphy>
          </Stack>
        ) : (
          <Stack alignItems={"center"} direction={"row"}>
            {weekNumber === 1 ? (
              <CustomIcon IconComponent={AccessTime} />
            ) : (
              <img className="MuiSvgIcon-root" src={weekLink} alt={weekLink} />
            )}

            <TypoGraphy variant="body1" typeClass={"semi-bold-font leftDays"}>
              {weekNumber === 1
                ? `${translate("STARTING_IN")} ${remainingDays(startDate)} ${translate("DAYS")}`
                : `${translate("AVAILABLE_WEEK")} ${weekNumber}`}
            </TypoGraphy>
          </Stack>
        )}
      </Grid>
      <Grid xs={4} item textAlign={"right"}>
        <TypoGraphy variant="body1" typeClass={"semi-bold-font"}>
          {averageHrs} {translate("HRS")}/{translate("WEEK")}
        </TypoGraphy>
      </Grid>
    </>
  );
};

export default KickOffHeader;
