import { Button, Grid, Stack } from "@mui/material";
import { Person } from "@mui/icons-material";
import TypoGraphy from "../../common/Typography";
import logo from "../../../styles/assets/images/sbux/logo.png";
import "./style.scss";
import useViewportWidth from "../../../utils/useViewportWidth";
import { smallDesktop } from "../../GlobalConstants";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AnalyticsContext } from "../../../analytics";

const Header: React.FC = () => {
  const windowWidth = useViewportWidth();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { storeData } = useAppSelector((state: any) => state.userStoreData);
  const analytics = useContext(AnalyticsContext);

  return (
    <>
      <header>
        <Grid container alignItems={"center"}>
          {windowWidth < smallDesktop ? (
            <>
              <Grid item xs={6}></Grid>
              <Grid item xs={6} textAlign={"right"}>
                <Button
                  variant="text"
                  endIcon={<Person />}
                  tabIndex={0}
                  onClick={() => {
                    analytics?.trackEvent({
                      name: `Profile button click`,
                      properties: {
                        page: "Header",
                        workflow: "View",
                        component: "Header",
                      },
                    });
                    navigate("/profile");
                  }}
                  className="user-info"
                  role="button"
                  aria-label={
                    storeData?.firstName &&
                    translate("HI") + ", " + storeData.firstName
                  }
                >
                  <TypoGraphy
                    variant={"h2"}
                    ariaLabel={translate("USER_DESCRIPTION")}
                  >
                    {storeData?.firstName &&
                      translate("HI") + ", " + storeData.firstName}
                  </TypoGraphy>
                </Button>
              </Grid>
              <Grid item xs={10}>
                <Stack className="header-message">
                  <TypoGraphy
                    variant={"h1"}
                    aria-label={translate("HEADER_MSG")}
                  >
                    {translate("HEADER_MSG")}
                  </TypoGraphy>
                </Stack>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={10}>
                <Stack direction={"row"}>
                  <Stack className="logo">
                    <img className="logo-img" src={logo} alt={"StarBucks"} />
                  </Stack>
                  <Stack className="header-message">
                    <TypoGraphy
                      variant={"h1"}
                      aria-label={translate("HEADER_MSG")}
                    >
                      {translate("HEADER_MSG")}
                    </TypoGraphy>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={2} textAlign={"right"}>
                <Button
                  variant="text"
                  endIcon={<Person />}
                  tabIndex={0}
                  onClick={() => {
                    analytics?.trackEvent({
                      name: `Edit button click`,
                      properties: {
                        page: "Preferences",
                        workflow: "Create",
                        component: "Step",
                      },
                    });
                    navigate("/profile");
                  }}
                  className="user-info"
                  role="button"
                  aria-label={
                    storeData?.firstName &&
                    translate("HI") + ", " + storeData.firstName
                  }
                >
                  <TypoGraphy
                    variant={"h2"}
                    ariaLabel={translate("USER_DESCRIPTION")}
                  >
                    {storeData?.firstName &&
                      translate("HI") + ", " + storeData.firstName}
                  </TypoGraphy>
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </header>
    </>
  );
};

export default Header;
