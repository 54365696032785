import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  SvgIconProps,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import CustomIcon from "../Icon";
import { useTranslation } from "react-i18next";

interface Props {
  headContent?: React.ReactNode;
  details?: React.ReactNode;
  headClass?: string;
  contentClass?: string;
  accordionClass?: string;
  iconClass?: string;
  iconName?: React.ElementType<SvgIconProps>;
  expanded: boolean;
  onChange: () => void;
}

const CustomAccordion: React.FC<Props> = ({
  headContent,
  headClass,
  contentClass,
  accordionClass: accordionClass,
  iconClass,
  details,
  iconName,
  expanded,
  onChange,
}) => {
  const { t: translate } = useTranslation();

  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      className={accordionClass}
      aria-expanded={expanded}
      aria-label={
        expanded
          ? translate("HIDE_ADDITIONAL_CONTENT")
          : translate("SHOW_ADDITIONAL_CONTENT")
      }
    >
      <AccordionSummary
        className={headClass}
        expandIcon={
          <CustomIcon
            iconClass={iconClass}
            IconComponent={iconName ?? ExpandMore}
            aria-label={
              expanded
                ? translate("HIDE_ADDITIONAL_CONTENT")
                : translate("SHOW_ADDITIONAL_CONTENT")
            }
          />
        }
      >
        {headContent}
      </AccordionSummary>
      <AccordionDetails className={contentClass}>{details}</AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
