import { createSlice } from "@reduxjs/toolkit";

export interface metaState {
  retryAttemptAvailability: number;
  isAvailabilityApiFinalError: boolean;
  onHomeScreen: boolean;
  retryAttemptPreferences: number;
  infoClicked: boolean;
  isNewUser: boolean;
  editPendingAvailability: boolean;
  editPendingAvailabilityData: any;
}

const initialState: metaState = {
  retryAttemptAvailability: 0,
  isAvailabilityApiFinalError: false,
  onHomeScreen: false,
  retryAttemptPreferences: 0,
  infoClicked: false,
  isNewUser: false,
  editPendingAvailability: false,
  editPendingAvailabilityData: [],
};

export const metaSlice = createSlice({
  name: "meta",
  initialState: initialState,
  reducers: {
    setRetryAttemptAvailability: (state, action) => {
      state.retryAttemptAvailability = action.payload;
    },
    setIsAvailabilityApiFinalError: (state, action) => {
      state.isAvailabilityApiFinalError = action.payload;
    },
    setOnHomeScreen: (state, action) => {
      state.onHomeScreen = action.payload;
    },
    setRetryAttemptPreferences: (state, action) => {
      state.retryAttemptPreferences = action.payload;
    },
    setInfoClicked: (state, action) => {
      state.infoClicked = action.payload;
    },
    setIsNewUser: (state, action) => {
      state.isNewUser = action.payload;
    },
    setEditPendingAvailability: (state, action) => {
      state.editPendingAvailability = action.payload;
    },
    setEditPendingAvailabilityData: (state, action) => {
      state.editPendingAvailabilityData = action.payload;
    },
  },
});

export const {
  setRetryAttemptAvailability,
  setIsAvailabilityApiFinalError,
  setOnHomeScreen,
  setRetryAttemptPreferences,
  setInfoClicked,
  setIsNewUser,
  setEditPendingAvailability,
  setEditPendingAvailabilityData,
} = metaSlice.actions;
export default metaSlice.reducer;
