import { Grid, Stack } from "@mui/material";
import TypoGraphy from "../../../../common/Typography";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AnalyticsContext } from "../../../../../analytics";

interface Props {
  averageHrs?: number | string;
  statusText?: string;
  statusClass?: string;
  statusIcon?: React.ReactNode;
}

const StatusLabel: React.FC<Props> = ({
  averageHrs,
  statusText,
  statusClass,
}) => {
  const { t: translate } = useTranslation();
  const analytics = useContext(AnalyticsContext);

  return (
    <>
      <Grid xs={6} item className="label-wrapper">
        <Stack
          className={`label ${statusClass}`}
          alignItems={"center"}
          justifyContent={"center"}
          direction={"row"}
          onClick={(e: any) => {
            analytics?.trackEvent({
              name: `Status label click`,
              properties: {
                page: "Availability",
                workflow: "Request view",
                component: "Request Status Label",
              },
            });
            e.stopPropagation();
          }}
        >
          <TypoGraphy variant="body1">{statusText}</TypoGraphy>
        </Stack>
      </Grid>
      <Grid xs={6} item textAlign={"right"} className="kick-status">
        <TypoGraphy variant="body1" typeClass={"semi-bold-font"}>
          {averageHrs} {translate("HRS")}/{translate("WEEK")}
        </TypoGraphy>
      </Grid>
    </>
  );
};

export default StatusLabel;
