import { Grid, IconButton, Stack } from "@mui/material";
import TypoGraphy from "../../common/Typography";
import CustomIcon from "../../common/Icon";
import { AccessTime } from "@mui/icons-material";
import { getMonthTranslations } from "../../../utils/getDays";
import { useTranslation } from "react-i18next";
import DateHeader from "../../shared/DateHeader/DateHeader";
import edit from "../../../styles/assets/svg/edit.svg";
import { PreferenceEntryToEdit } from "./Create";
import { useContext } from "react";
import { AnalyticsContext } from "../../../analytics";

interface Props {
  preferenceEntryToEdit: PreferenceEntryToEdit;
  current?: boolean;
  editingEnabled?: boolean;
  toggleDrawer: (
    open: boolean,
    preferenceEntryToEdit?: PreferenceEntryToEdit,
  ) => void;
}

const PreferredCardHeader: React.FC<Props> = ({
  preferenceEntryToEdit,
  current,
  editingEnabled,
  toggleDrawer,
}) => {
  const { t: translate } = useTranslation();
  const { remainingDays } = getMonthTranslations();
  const analytics = useContext(AnalyticsContext);

  return (
    <>
      <Grid xs={12} item>
        <Grid
          xs={12}
          item
          container
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Stack className="date-header-left">
            <DateHeader
              startDate={preferenceEntryToEdit.existingStartDate}
              endDate={preferenceEntryToEdit.existingEndDate}
              current={current}
            />
          </Stack>
          {editingEnabled && (
            <IconButton
              onClick={() => {
                analytics?.trackEvent({
                  name: `Edit Preference click`,
                  properties: {
                    page: "Preferences",
                    workflow: "Preferences view",
                    component: "Preference Card Header",
                  },
                });
                toggleDrawer(true, preferenceEntryToEdit);
              }}
              className={"edit-btn"}
            >
              <img
                src={edit}
                alt={edit}
                aria-label={translate("EDIT_PREFERRED_HOURS")}
              />
            </IconButton>
          )}
        </Grid>
        {!current && (
          <Grid xs={8} item className={"kick-status"}>
            <Stack alignItems={"center"} direction={"row"}>
              <CustomIcon IconComponent={AccessTime} />
              <TypoGraphy variant="body1" typeClass={"regular-font"}>
                {`${translate("STARTING_IN")} ${remainingDays(preferenceEntryToEdit.existingStartDate)} ${translate("DAYS")}`}
              </TypoGraphy>
            </Stack>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PreferredCardHeader;
