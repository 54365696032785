import { useContext, useEffect, useState } from "react";
import Current from "./Current";
import Future from "./Future";
import {
  getPreferences,
  getPreferencesAttribute,
} from "../../../features/preferencesReducer/action";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { Grid, Stack } from "@mui/material";
import ErrorPage from "../../shared/ErrorPage";
import "./style.scss";
import CustomBtn from "../../common/Button";
import { Add } from "@mui/icons-material";
import Create, { PreferenceEntryToEdit } from "./Create";
import useViewportWidth from "../../../utils/useViewportWidth";
import {
  PREFERENCE_MAXIMUM_ALLOW_DAYS,
  smallDesktop,
} from "../../GlobalConstants";
import { useTranslation } from "react-i18next";
import Loader from "../../shared/Loader";
import moment from "moment";
import Warning from "../../../styles/assets/svg/warning-icon.svg";
import { Typography } from "@mui/material";
import { setOnHomeScreen } from "../../../features/metaReducer";
import { AnalyticsContext, PageType } from "../../../analytics";

const Preferences: React.FC = () => {
  const [currentPreferencesData, setCurrentData] = useState<any>();
  const [futurePreferencesData, setFutureData] = useState<any>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [lastPreferredDate, setLastPreferredDate] = useState<string>("");
  const [preferenceEntryToEdit, setPreferenceEntryToEdit] =
    useState<PreferenceEntryToEdit>();
  const [currentEditingEnabled, setCurrentEditingEnabled] =
    useState<boolean>(false);
  const [preferenceLimitReached, setPreferenceLimitReached] =
    useState<boolean>(false);
  const [attributeApiError, setAttributeApiError] = useState<boolean>(false);
  const { t: translate } = useTranslation();
  const dispatch: any = useAppDispatch();
  const analytics = useContext(AnalyticsContext);

  useEffect(
    () =>
      analytics?.trackPageView({
        name: "My Preferences",
        pageType: PageType.Document,
      }),
    [],
  );

  const toggleDrawer = (
    open: boolean,
    selectedPreferenceEntryToEdit?: PreferenceEntryToEdit,
  ) => {
    if (!open) {
      // Clear edit state
      setPreferenceEntryToEdit(undefined);
    } else if (selectedPreferenceEntryToEdit) {
      // Set edit state
      setPreferenceEntryToEdit(selectedPreferenceEntryToEdit);
    }

    setOpenDrawer(open);
  };

  const { preferredHours, success: attrSuccess } = useAppSelector(
    (state: any) => state.preferencesAttribute,
  );
  const {
    preferencesData,
    success: preferSuccess,
    loading,
  } = useAppSelector((state: any) => state.preferences);

  const { onHomeScreen } = useAppSelector((state: any) => state.meta);

  useEffect(() => {
    dispatch(setOnHomeScreen(true));
  }, []);

  const windowWidth = useViewportWidth();

  useEffect(() => {
    if (preferSuccess === false) {
      dispatch(getPreferences())
        .then((action: any) => {
          const response = action.payload;
          if (response.data && response.errorResponse === null) {
            setCurrentData(
              response?.data?.currentPreferredHours
                ? response?.data?.currentPreferredHours
                : null,
            );
            setFutureData(
              response?.data?.futurePreferredHours
                ? response?.data?.futurePreferredHours
                : [],
            );
          } else {
            analytics?.trackEvent({
              name: `Error: Invalid response for getPreferences`,
              properties: {
                page: "Preferences",
                workflow: "View",
                component: "Preferences",
                error: response,
              },
            });
            setHasError(true);
          }
        })
        .catch((e: any) => {
          analytics?.trackEvent({
            name: `Error: Error during getPreferences`,
            properties: {
              page: "Preferences",
              workflow: "View",
              component: "Preferences",
              error: e,
            },
          });
          setHasError(true);
        });
    }
  }, []);

  useEffect(() => {
    if (attrSuccess) {
      return;
    }
    dispatch(getPreferencesAttribute())
      .then((response: any) => {
        if (
          response &&
          response?.payload?.errorResponse === null &&
          response?.payload?.data &&
          response?.payload?.data?.entities?.length !== 0
        ) {
          setAttributeApiError(false);
        } else {
          analytics?.trackEvent({
            name: `Error: Error response for getPreferencesAttribute`,
            properties: {
              page: "Preferences",
              workflow: "View",
              component: "Preferences",
              error: response,
            },
          });
          setAttributeApiError(true);
        }
      })
      .catch((e: any) => {
        analytics?.trackEvent({
          name: `Error: Error during getPreferencesAttribute`,
          properties: {
            page: "Preferences",
            workflow: "View",
            component: "Preferences",
            error: e,
          },
        });
        setAttributeApiError(true);
      });
  }, []);

  useEffect(() => {
    if (preferencesData) {
      setCurrentData(preferencesData?.currentPreferredHours);
      setFutureData(preferencesData?.futurePreferredHours);
    }
    if (preferredHours) {
      if (preferredHours.status === 404) {
        analytics?.trackEvent({
          name: `Error: 404 from preferredHours call`,
          properties: {
            page: "Preferences",
            workflow: "View",
            component: "Preferences",
          },
        });
        setAttributeApiError(true);
      }
    }
  }, [preferencesData, preferredHours]);

  useEffect(() => {
    if (futurePreferencesData?.length > 0) {
      setLastPreferredDate(
        futurePreferencesData[futurePreferencesData?.length - 1]?.start,
      );

      const daysBetweenTodayAndFutureStartDate = moment(
        futurePreferencesData[futurePreferencesData?.length - 1].start,
      )
        .utc()
        .add(7, "days")
        .diff(moment(new Date()).utc(), "days");
      if (daysBetweenTodayAndFutureStartDate > PREFERENCE_MAXIMUM_ALLOW_DAYS) {
        analytics?.trackEvent({
          name: `Error: preference limit reached`,
          properties: {
            page: "Preferences",
            workflow: "View",
            component: "Preferences",
          },
        });
        setPreferenceLimitReached(true);
      }
    }
  }, [futurePreferencesData]);

  useEffect(() => {
    if (currentPreferencesData) {
      if (
        currentPreferencesData?.end === null &&
        (currentPreferencesData?.minHoursPerWeek === 0 ||
          currentPreferencesData?.maxHoursPerWeek === 0 ||
          currentPreferencesData?.preferredHoursPerWeek === 0)
      ) {
        setCurrentEditingEnabled(true);
      } else {
        setCurrentEditingEnabled(false);
      }
    }
  }, [currentPreferencesData]);

  return (
    <>
      {hasError ? (
        <ErrorPage />
      ) : loading && onHomeScreen ? (
        <Loader />
      ) : (
        <Grid container item xs={12} className="parallel-grid preference-grid">
          <Grid item xs={12} md={6} className="first-grid">
            <Current
              currentPreferencesData={currentPreferencesData}
              current={true}
              loading={loading}
              editingEnabled={currentEditingEnabled}
              toggleDrawer={toggleDrawer}
            />
          </Grid>
          <Grid item xs={12} md={6} className="last-grid">
            <Future
              futureData={futurePreferencesData}
              currentPreferencesData={currentPreferencesData}
              current={false}
              lastPreferredDate={lastPreferredDate}
              editingEnabled={!attributeApiError}
              toggleDrawer={toggleDrawer}
            />
            {windowWidth >= smallDesktop && (
              <CustomBtn
                startIcon={<Add />}
                text={translate("PREFERRED_HOURS")}
                btnClass={"primary-btn"}
                aria-label={translate("CREATE_PREFERENCES_BUTTON")}
                onClick={() => {
                  analytics?.trackEvent({
                    name: `Add Preferred Hours click`,
                    properties: {
                      page: "Preferences",
                      workflow: "Preferences view",
                      component: "Add Preferred Hours Button",
                    },
                  });
                  toggleDrawer(true);
                }}
                disabled={
                  preferenceLimitReached ||
                  currentEditingEnabled ||
                  attributeApiError
                }
              />
            )}
            {attributeApiError && (
              <Stack className="error-block">
                <div style={{ display: "flex" }}>
                  <img src={Warning} alt={"error"} className="warning-icon" />
                  <div className="error-div">
                    <Typography
                      id={"error-message-preference-title"}
                      variant="body1"
                    >
                      {translate("SYSTEM_UNAVAILABLE")}
                    </Typography>
                    <Typography id={"error-message-preference-subtitle"}>
                      {translate("NEW_REQUEST_UNAVAILABLE")}
                    </Typography>
                  </div>
                </div>
              </Stack>
            )}
            {preferenceLimitReached && (
              <Stack className="error-block">
                <div style={{ display: "flex" }}>
                  <img src={Warning} alt={"error"} className="warning-icon" />
                  <div className="error-div">
                    <Typography
                      id={"error-message-preference-title"}
                      variant="body1"
                    >
                      {translate("PREFERRED_LIMIT_REACHED_MESSAGE")}
                    </Typography>
                    <Typography id={"error-message-preference-subtitle"}>
                      {translate("PREFERRED_LIMIT_REACHED_SUB_MESSAGE")}
                    </Typography>
                  </div>
                </div>
              </Stack>
            )}
          </Grid>
          {windowWidth < smallDesktop && (
            <CustomBtn
              startIcon={<Add />}
              text={translate("PREFERRED_HOURS")}
              btnClass={"primary-btn"}
              aria-label={translate("CREATE_PREFERENCES_BUTTON")}
              onClick={() => {
                analytics?.trackEvent({
                  name: `Add Preferred Hours click`,
                  properties: {
                    page: "Preferences",
                    workflow: "Preferences view",
                    component: "Add Preferred Hours Button",
                  },
                });
                toggleDrawer(true);
              }}
              disabled={
                preferenceLimitReached ||
                currentEditingEnabled ||
                attributeApiError
              }
            />
          )}
          {openDrawer && (
            <Create
              toggleDrawer={toggleDrawer}
              editingEnabled={currentEditingEnabled}
              lastPreferredDate={lastPreferredDate}
              preferenceEntryToEdit={preferenceEntryToEdit}
            />
          )}
        </Grid>
      )}
    </>
  );
};

export default Preferences;
