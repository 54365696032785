import { handleFetchError } from "../../utils/errorHandler";

interface HttpClient {
  get(url: string): Promise<Response>;
}

class AuthDetailsService {
  private httpClient: HttpClient;
  private url: string;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
    this.url = `/auth`;
  }

  async getData(): Promise<any> {
    try {
      const response = await this.httpClient.get(this.url);
      if (response.status === 200) {
        return response.json();
      } else {
        return response;
      }
    } catch (error: any) {
      const errorMessage = handleFetchError(error);
      console.error("API Error - Auth Details error:", errorMessage);
      return null;
    }
  }
}

export default AuthDetailsService;
