import { createSlice } from "@reduxjs/toolkit";
import { getAvailabilityRequest, makeAvailabilityRequest } from "./action";

export interface AvailableRequestState {
  availabilityRequestData: any;
  loading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: AvailableRequestState = {
  availabilityRequestData: [],
  loading: false,
  error: null,
  success: false,
};

export const availabilityRequestSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    UPDATE_AVAILABLE_REQUEST: (state, action) => {
      state.availabilityRequestData = action.payload;
    },
    DELETE_FUTURE_AVAILABILITY: (state, action) => {
      state.availabilityRequestData = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        getAvailabilityRequest.pending,
        (state: AvailableRequestState) => {
          state.loading = true;
          state.success = false;
        },
      )
      .addCase(
        getAvailabilityRequest.fulfilled,
        (state: AvailableRequestState, action: any) => {
          state.loading = false;
          state.availabilityRequestData = action.payload;
          state.success = true;
        },
      )
      .addCase(
        getAvailabilityRequest.rejected,
        (state: AvailableRequestState, action: any) => {
          state.loading = false;
          state.error = action.payload;
          state.success = false;
        },
      )

      .addCase(
        makeAvailabilityRequest.pending,
        (state: AvailableRequestState) => {
          state.loading = true;
          state.success = false;
        },
      )
      .addCase(
        makeAvailabilityRequest.fulfilled,
        (state: AvailableRequestState, action: any) => {
          state.loading = false;
          state.availabilityRequestData = action.payload;
          state.success = true;
        },
      )
      .addCase(
        makeAvailabilityRequest.rejected,
        (state: AvailableRequestState, action: any) => {
          state.loading = false;
          state.error = action.payload;
          state.success = false;
        },
      );
  },
});

export default availabilityRequestSlice.reducer;
