import { Grid } from "@mui/material";
import DateHeader from "../../../../shared/DateHeader/DateHeader";
import StatusLabel from "./StatusLabel";
import { formatTimeForNewRequestTime } from "../../../../../utils/durationCalculate";

interface Props {
  averageHrs?: number | string;
  startDate?: string;
  endDate?: string;
  statusText?: string;
  statusClass?: string;
}

const RequestAccHeader: React.FC<Props> = ({
  startDate,
  endDate,
  averageHrs,
  statusText,
  statusClass,
}) => {
  return (
    <Grid item container xs={12}>
      <Grid item xs={12} alignItems="center">
        <DateHeader startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid item container xs={12} alignItems={"center"} className="mt-1">
        <StatusLabel
          statusText={statusText}
          averageHrs={
            typeof averageHrs === "string"
              ? formatTimeForNewRequestTime(averageHrs)
              : averageHrs
          }
          statusClass={statusClass}
        />
      </Grid>
    </Grid>
  );
};

export default RequestAccHeader;
