import { createSlice } from "@reduxjs/toolkit";
import { getAuthDetails } from "./action";

export interface AuthDetails {
  userAuthDetails: any;
  loadingAuthData: boolean;
  error: string | null;
  success: boolean;
}

const initialState: AuthDetails = {
  userAuthDetails: null,
  loadingAuthData: false,
  error: null,
  success: false,
};

export const authDetailsSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAuthDetails.pending, (state: AuthDetails) => {
        state.loadingAuthData = true;
        state.success = false;
      })
      .addCase(getAuthDetails.fulfilled, (state: AuthDetails, action: any) => {
        localStorage.setItem("userEmployeeId", action.payload);
        state.loadingAuthData = false;
        state.userAuthDetails = action.payload;
        state.success = action.payload.data !== null ? true : false;
      })
      .addCase(getAuthDetails.rejected, (state: AuthDetails, action: any) => {
        state.loadingAuthData = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

export default authDetailsSlice.reducer;
