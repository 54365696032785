import { handleFetchError } from "../../utils/errorHandler";

interface HttpClient {
  get(url: string): Promise<Response>;
  post(url: string, data: any): Promise<Response>;
}

class AvailabilityAllService {
  private httpClient: HttpClient;
  private url: string;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
    this.url = `/partner/availability/all`;
  }

  async getData(param: any): Promise<any> {
    try {
      const response: any = await this.httpClient.get(
        this.url + `?partnerId=${param}`,
      );
      if (response && response.errorResponse === null) {
        const updatedPartnerId =
          response.data?.availabilityResponse.partnerId ?? "";
        localStorage.setItem("userEmployeeId", updatedPartnerId);
        return response;
      } else if (response.errorResponse !== null) {
        const errorMessage = handleFetchError(response);
        console.error("API Error:", errorMessage);
        return response.errorResponse.status;
      }
    } catch (error: any) {
      const errorMessage = handleFetchError(error);
      console.error(`API Error: ${errorMessage}`);
    }
  }
}

export default AvailabilityAllService;
