import { useContext, useEffect } from "react";
import "./style.scss";
import { Grid, Stack } from "@mui/material";
import { WarningRounded } from "@mui/icons-material";
import CustomIcon from "../../common/Icon";
import TypoGraphy from "../../common/Typography";
import { useTranslation } from "react-i18next";
import { AnalyticsContext, PageType } from "../../../analytics";

const AccessDenied: React.FC = () => {
  const { t: translate } = useTranslation();
  const analytics = useContext(AnalyticsContext);

  useEffect(
    () =>
      analytics?.trackPageView({
        name: "Access Denied",
        pageType: PageType.Document,
      }),
    [],
  );

  return (
    <Grid
      xs={12}
      item
      container
      justifyContent={"center"}
      alignItems={"center"}
      className="page-container"
    >
      <Grid
        xs={12}
        className="access-denied-content"
        justifyContent={"space-between"}
        container
        item
      >
        <Stack alignItems={"center"} spacing={1}>
          <CustomIcon IconComponent={WarningRounded} />
          <TypoGraphy variant="h1" typeClass={"semi-bold-font"}>
            {translate("ACCESS_DENIED")}
          </TypoGraphy>
          <TypoGraphy variant="body1" typeClass={"regular-font"}>
            {translate("ACCESS_DENIED_MESSAGE")}
          </TypoGraphy>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AccessDenied;
