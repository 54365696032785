export const CANCELLED = "Cancelled";
export const DENIED = "Denied";
export const PENDING = "Pending";
export const EXPIRED = "Expired";

export const BUTTON = "button";
export const DIALOG = "dialog";
export const smallDesktop = 900;
export const normalDesktop = 1200;
export const END_DATE_INCLUDE_DOT_1_Value = "12:00 AM";
export const ALL_DAY_HOURS = 0;
export const MAX_CHARACTERS_ALLOWED_IN_AVAILABILITY_COMMENT = 255;
export const LOCAL_STORAGE_LANGUAGE = "preferredLanguage";
export const AVAILABILITY_MAX_DAY_FOR_CREATE = 21;
export const PREFERENCE_MAXIMUM_ALLOW_DAYS = 60;
// TODO: Add local storage constant for user ID with https://sim.starbucks.com/browse/TWP-48313.
