import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider, TAuthConfig } from "react-oauth2-code-pkce";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import "./styles/main.scss";
import { defaultTheme } from "./styles/theme";
import { store } from "./redux/store";
import ErrorBoundary from "./components/shared/ErrorBoundary";
import "./i18n";
import { AnalyticsContext } from "./analytics";

const authConfig: TAuthConfig = {
  clientId: process.env.REACT_APP_CLIENT_ID ?? "",
  authorizationEndpoint: process.env.REACT_APP_AUTH_ENDPOINT ?? "",
  tokenEndpoint: process.env.REACT_APP_TOKEN_ENDPOINT ?? "",
  redirectUri: process.env.REACT_APP_REDIRECT_URI ?? "",
  decodeToken: false,
  scope: process.env.REACT_APP_SCOPE,
  clearURL: true,
  autoLogin: process.env.REACT_APP_IS_SSO_ENABLED === "true" ? true : false,
  logoutRedirect: "/",
};

// Initialize App Insights for UI analytics.
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_AZURE_INSIGHTS_CONNECTION_STRING,
    disableAjaxTracking: true,
    disableFetchTracking: true,
    enableAjaxPerfTracking: false,
    enableRequestHeaderTracking: false,
  },
});
appInsights.loadAppInsights();
// When in a non-prod environment, log event data to console for testing.
if (
  process.env.REACT_APP_ENV !== "prod" &&
  process.env.REACT_APP_ENV !== "ct"
) {
  appInsights.addTelemetryInitializer((item) => {
    console.log("Analytics: " + item.baseType, item.baseData);
  });
}

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <Provider store={store}>
        <AnalyticsContext.Provider value={appInsights}>
          <ErrorBoundary analytics={appInsights}>
            <AuthProvider authConfig={authConfig}>
              <App />
            </AuthProvider>
          </ErrorBoundary>
        </AnalyticsContext.Provider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
);

reportWebVitals();
