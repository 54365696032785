import { Grid } from "@mui/material";
import SPH from "./SPH";
import DeniedRequests from "./DeniedRequests";
import PendingRequests from "./PendingRequests";
import CancelledRequests from "./CancelledRequests";
import { useEffect, useState } from "react";
import { CANCELLED, DENIED, PENDING, EXPIRED } from "../../../GlobalConstants";
import ExpiredRequests from "./ExpiredRequests";

interface AvailabilityRequest {
  id: number;
  status: string;
}

interface Props {
  requestsData: AvailabilityRequest[];
}

const RequestCardContent: React.FC<Props> = ({ requestsData }) => {
  const [expandedId, setExpandedId] = useState<number | null>(null);
  const [pendingRequests, setPendingRequests] = useState<AvailabilityRequest[]>(
    [],
  );
  const [deniedRequests, setDeniedRequests] = useState<AvailabilityRequest[]>(
    [],
  );
  const [cancelledRequests, setCancelledRequests] = useState<
    AvailabilityRequest[]
  >([]);
  const [expiredRequests, setExpiredRequests] = useState<AvailabilityRequest[]>(
    [],
  );

  const handleAccordionChange = (id: number) => {
    setExpandedId((prevId) => (prevId === id ? null : id));
  };

  useEffect(() => {
    const pendingData = requestsData?.filter(
      (request) => request.status === PENDING,
    );
    const deniedData = requestsData?.filter(
      (request) => request.status === DENIED,
    );
    const cancelledData = requestsData?.filter(
      (request) => request.status === CANCELLED,
    );
    const expiredData = requestsData?.filter(
      (request) => request.status === EXPIRED,
    );
    setPendingRequests(pendingData || []);
    setDeniedRequests(deniedData || []);
    setCancelledRequests(cancelledData || []);
    setExpiredRequests(expiredData || []);
  }, [requestsData]);
  return (
    <>
      <Grid container item>
        {!(process.env.REACT_APP_IS_V2 === "true") && <SPH />}
        <PendingRequests
          genAvailData={pendingRequests}
          expandedId={expandedId}
          onAccordionChange={handleAccordionChange}
        />
        <DeniedRequests
          genAvailData={deniedRequests}
          expandedId={expandedId}
          onAccordionChange={handleAccordionChange}
        />
        <CancelledRequests
          genAvailData={cancelledRequests}
          expandedId={expandedId}
          onAccordionChange={handleAccordionChange}
        />
        <ExpiredRequests
          genAvailData={expiredRequests}
          expandedId={expandedId}
          onAccordionChange={handleAccordionChange}
        />
      </Grid>
    </>
  );
};

export default RequestCardContent;
