import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { CloseRounded } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import TypoGraphy from "../Typography";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import "./style.scss";
import useViewportWidth from "../../../utils/useViewportWidth";
import { smallDesktop } from "../../GlobalConstants";
import { useTranslation } from "react-i18next";
import { PreferenceEntryToEdit } from "../../pages/Preferences/Create";

interface Props {
  anchor: "top" | "left" | "bottom" | "right";
  toggleDrawer: (
    open: boolean,
    preferenceEntryToEdit?: PreferenceEntryToEdit,
  ) => void;
  drawerClass?: string;
  closeBtnClass?: string;
  drawerTitle?: React.ReactNode;
  titleClass?: string;
  children?: React.ReactNode;
  onCustomAction?: () => void;
}

const CustomDrawer: React.FC<Props> = ({
  anchor,
  toggleDrawer,
  drawerClass,
  closeBtnClass,
  drawerTitle,
  titleClass,
  children,
  onCustomAction,
}) => {
  const windowWidth = useViewportWidth();
  const handleCustomAction = () => {
    if (onCustomAction) {
      onCustomAction();
    }
  };
  const { t: translate } = useTranslation();

  return (
    <Drawer
      anchor={anchor}
      open={true}
      onClose={() => toggleDrawer(false)}
      className={drawerClass}
      role="dialog"
      aria-labelledby={translate("DRAWER_TITLE")}
    >
      <Box role="presentation">
        {windowWidth < smallDesktop ? (
          <>
            <Grid container item xs={12} justifyContent={"flex-end"}>
              <IconButton
                onClick={() => {
                  handleCustomAction();
                }}
                className={closeBtnClass}
                aria-label={translate("CLOSE_DRAWER")}
              >
                <CloseRounded />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <TypoGraphy variant="h1" component={"h1"} typeClass={titleClass}>
                {drawerTitle}
              </TypoGraphy>
            </Grid>

            <SimpleBar className="drawer-scroll" tabIndex={-1}>
              <Grid container item xs={12}>
                {children}
              </Grid>
            </SimpleBar>
          </>
        ) : (
          <Grid container item xs={12}>
            {children}
          </Grid>
        )}
      </Box>
    </Drawer>
  );
};

export default CustomDrawer;
