import { createAsyncThunk } from "@reduxjs/toolkit";
import availabilityRequestsService from "../../services/availabilityRequests";
import ApiService from "../../services/commonServices/apiService";

const AvailRequestsService: availabilityRequestsService =
  new availabilityRequestsService(ApiService);

export const getAvailabilityRequest = createAsyncThunk(
  `availabilityRequests/getData`,
  async () => {
    const employeeId = localStorage.getItem("userEmployeeId");
    const res = await AvailRequestsService.getData(employeeId);
    return res;
  },
);

export const makeAvailabilityRequest = createAsyncThunk(
  `availabilityRequests/createData`,
  async (CreateRequestParam: any) => {
    const res =
      await AvailRequestsService.createAvailabilityData(CreateRequestParam);
    return res;
  },
);

export const updateAvailabilityRequest = createAsyncThunk(
  `availabilityRequests/updateData`,
  async (CreateRequestParam: any) => {
    const res =
      await AvailRequestsService.updateAvailabilityData(CreateRequestParam);
    return res;
  },
);

export const deleteAvailabilityRequest = createAsyncThunk(
  `availabilityRequests/deleteData`,
  async (CreateRequestParam: any) => {
    const res =
      await AvailRequestsService.deleteAvailabilityData(CreateRequestParam);
    return res;
  },
);
