import MainCard from "../../../common/Card";
import CardContent from "../CardContent";
import Typography from "../../../common/Typography";
import LoadUI from "../../../shared/NoData/LoadUI";
import NoAvailability from "../../../shared/NoData/NoAvailability";
import { useTranslation } from "react-i18next";
import { PreferenceEntryToEdit } from "../Create";

interface Props {
  currentPreferencesData: any;
  current?: boolean;
  loading?: boolean;
  editingEnabled?: boolean;
  toggleDrawer: (
    open: boolean,
    preferenceEntryToEdit?: PreferenceEntryToEdit,
  ) => void;
}

const Current: React.FC<Props> = ({
  currentPreferencesData,
  current,
  loading,
  editingEnabled,
  toggleDrawer,
}) => {
  const { t: translate } = useTranslation();

  return (
    <>
      {loading ? (
        <LoadUI />
      ) : (
        <>
          <Typography
            variant="h2"
            typeClass="card-header-title"
            aria-describedby={translate("MY_CURRENT_PREFERENCES")}
            tabIndex={-1}
          >
            {translate("MY_CURRENT_PREFERENCES")}
          </Typography>
          {typeof currentPreferencesData === "undefined" ||
          Object.getOwnPropertyNames(currentPreferencesData)?.length === 0 ? (
            <NoAvailability
              className="blank-grid preferred-current-grid"
              message={translate("NO_PREFERRED_HOURS_CURRENTLY_LISTED")}
            />
          ) : (
            <MainCard
              key={currentPreferencesData?.attributeAssignmentId}
              cardClass={"main-card"}
              contentClass={"card-content current-card preference-card"}
            >
              <CardContent
                preferencesData={currentPreferencesData}
                current={current}
                editingEnabled={editingEnabled}
                toggleDrawer={toggleDrawer}
              />
            </MainCard>
          )}
        </>
      )}
    </>
  );
};

export default Current;
