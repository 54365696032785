import React, { useState } from "react";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import MainCard from "../../../common/Card";
import CardContent from "../CardContent";
import TypoGraphy from "../../../common/Typography";

interface Props {
  futureData: any[] | undefined;
  current?: boolean;
}

const Future: React.FC<Props> = ({ futureData, current }) => {
  const [expandedId, setExpandedId] = useState<number | null>(null);
  const [expandedWeekIndex, setExpandedWeekIndex] = useState<number | null>(
    null,
  );
  const { t: translate } = useTranslation();

  const handleAccordionChange = (objectIndex: number, weekIndex: number) => {
    if (expandedId !== objectIndex) {
      setExpandedWeekIndex(null);
    }
    setExpandedId(objectIndex);
    setExpandedWeekIndex((prevIndex) =>
      prevIndex === weekIndex ? null : weekIndex,
    );
  };

  return (
    <>
      {futureData?.length !== 0 && typeof futureData !== "undefined" && (
        <>
          <TypoGraphy
            variant="h2"
            typeClass="card-header-title"
            aria-describedby={translate("MY_FUTURE_AVAILABILITY")}
          >
            {translate("MY_FUTURE_AVAILABILITY")}
          </TypoGraphy>

          {futureData?.map((weekData: any, index: any) => (
            <div key={weekData?.id}>
              <TypoGraphy
                variant="h3"
                typeClass="week-header-title"
                aria-describedby={`Week ${weekData?.weekNumber}`}
              ></TypoGraphy>
              {weekData?.weeklyAvailabilityResponseList?.map(
                (
                  availData: {
                    weekNumber: number | null | undefined;
                    generalAvailability: any;
                  },
                  weekIndex: any,
                ) => {
                  const weekNumber = availData?.weekNumber;
                  if (weekNumber != null) {
                    return (
                      <React.Fragment key={weekNumber}>
                        <MainCard
                          cardClass={"main-card"}
                          contentClass={
                            weekIndex === 0
                              ? "card-content future-card available-card"
                              : weekData?.weeklyAvailabilityResponseList
                                    ?.length >= 2
                                ? "card-current-content current-card-dash available-card-dashed"
                                : "card-content future-card available-card"
                          }
                        >
                          <CardContent
                            genAvailData={availData?.generalAvailability}
                            endDate={weekData?.endDate}
                            startDate={weekData?.startDate}
                            current={current}
                            weekNumber={weekNumber}
                            expandedId={
                              expandedId === index &&
                              expandedWeekIndex === weekIndex
                                ? weekNumber
                                : null
                            }
                            onAccordionChange={() =>
                              handleAccordionChange(index, weekIndex)
                            }
                          />
                        </MainCard>

                        {weekData?.weeklyAvailabilityResponseList?.length > 1 &&
                          weekIndex !==
                            weekData?.weeklyAvailabilityResponseList?.length -
                              1 && <Stack className="vertical-dotted-lines" />}
                      </React.Fragment>
                    );
                  } else {
                    return null;
                  }
                },
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default Future;
