import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en_US.json";
import translationFR from "./locales/fr_CA.json";
import { LOCAL_STORAGE_LANGUAGE } from "./components/GlobalConstants";

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

void i18n.use(initReactI18next).init({
  lng: localStorage.getItem(LOCAL_STORAGE_LANGUAGE) ?? "en",
  resources,
  fallbackLng: ["en", "fr"],
  interpolation: {
    escapeValue: false,
  },
});

export function changeLanguage(lng: string) {
  void i18n.changeLanguage(lng);
}

export default i18n;
