import { Grid } from "@mui/material";
import Availability from "../Availability";
import Preferences from "../Preferences";
import { useTranslation } from "react-i18next";
import CustomTab from "../../common/Tabs";
import { tabItems } from "../../common/Tabs/models";
import Header from "../../shared/Header";

const UserSchedule: React.FC = () => {
  const { t: translate } = useTranslation();

  const tabs: tabItems[] = [
    {
      label: translate("MY_AVAILABILITY"),
      content: <Availability />,
    },
    {
      label: translate("MY_PREFERENCES"),
      content: <Preferences />,
    },
  ];

  const ariaLabel = {
    AvailabilityPreferencesRequests: translate(
      "USER_AVAILABILITY_PREFERENCES_REQUESTS",
    ),
  };

  return (
    <>
      <Header />
      <Grid component="main">
        <CustomTab
          tabs={tabs}
          tabClass="user-tabs"
          ariaLabel={ariaLabel.AvailabilityPreferencesRequests}
        />
      </Grid>
    </>
  );
};

export default UserSchedule;
