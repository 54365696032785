import HttpInterceptor from "../authServices/index";

const API_BASE_URL = `${process.env.REACT_APP_BASE_URL}`;
// This is a temporary change. REACT_APP_BASE_URL will be the final endpoint after all the API changes are completed.
// Link to API documentation -- https://docs.starbucks.com/pages/viewpage.action?spaceKey=WFM&title=API+Design+and+Definition
const API_POST_BASE_URL = `${process.env.REACT_APP_POST_APP_BASE_URL}`;

class ApiService {
  private httpInterceptor: HttpInterceptor;
  private httpPOSTInterceptor: HttpInterceptor;

  constructor() {
    this.httpInterceptor = new HttpInterceptor(API_BASE_URL);
    this.httpPOSTInterceptor = new HttpInterceptor(API_POST_BASE_URL);
  }

  public async get(url: string): Promise<any> {
    return this.httpInterceptor.get(url);
  }

  public async post(url: string, data: any): Promise<any> {
    return this.httpInterceptor.post(url, data);
  }

  // This is a temporary change. post will be the final method after all the API changes are completed.
  public async postAvailability(url: string, data: any): Promise<any> {
    return this.httpPOSTInterceptor.post(url, data);
  }

  public async update(url: string, data: any): Promise<any> {
    return this.httpInterceptor.put(url, data);
  }

  public async updateAvailability(url: string, data: any): Promise<any> {
    return this.httpPOSTInterceptor.put(url, data);
  }

  public async delete(url: string): Promise<void> {
    return this.httpInterceptor.delete(url);
  }
}

export default new ApiService();
