import { CircularProgress, Grid, Stack } from "@mui/material";
import React from "react";
import "./style.scss";

const LoadUI: React.FC = () => {
  return (
    <Grid
      xs={12}
      item
      container
      className="noData-container"
      direction={"row"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack>
        <CircularProgress color="success" variant="indeterminate" />
      </Stack>
    </Grid>
  );
};

export default LoadUI;
