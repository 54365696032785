import MainCard from "../../../common/Card";
import TypoGraphy from "../../../common/Typography";
import CardContent from "../CardContent";
import NoAvailability from "../../../shared/NoData/NoAvailability";
import { useTranslation } from "react-i18next";
import { PreferenceEntryToEdit } from "../Create";
import dayjs from "dayjs";

interface Props {
  futureData: any;
  currentPreferencesData: any;
  current?: boolean;
  lastPreferredDate?: string;
  editingEnabled?: boolean;
  toggleDrawer: (
    open: boolean,
    preferenceEntryToEdit?: PreferenceEntryToEdit,
  ) => void;
}

const Future: React.FC<Props> = ({
  futureData,
  current,
  lastPreferredDate,
  toggleDrawer,
  editingEnabled,
}) => {
  const { t: translate } = useTranslation();

  const shouldEnable = (date: Date | string | undefined) => {
    const allowFromDays = `${process.env.REACT_APP_NEW_PREFERENCE_ALLOW_FROM_DAYS}`;
    const allowFrom = dayjs().add(Number(allowFromDays), "day");

    if (dayjs(date).isBefore(allowFrom, "day")) {
      return false;
    }

    return true;
  };

  return (
    <>
      <TypoGraphy
        variant="h2"
        typeClass="card-header-title"
        ariaLabel={translate("MY_FUTURE_PREFERENCES")}
      >
        {translate("MY_FUTURE_PREFERENCES")}
      </TypoGraphy>

      {futureData?.length === 0 || typeof futureData === "undefined" ? (
        <NoAvailability
          className="blank-grid preferred-future-grid"
          message={translate(
            "YOU_HAVE_NO_FUTURE_PREFERRED_HOURS_CURRENTLY_LISTED",
          )}
        />
      ) : (
        futureData?.map((futureItem: any) => (
          <MainCard
            cardClass={"main-card"}
            contentClass={"card-content future-card preference-card"}
            key={futureItem?.attributeAssignmentId}
          >
            <CardContent
              editingEnabled={
                !!editingEnabled && shouldEnable(futureItem.start)
              }
              preferencesData={futureItem}
              current={current}
              lastPreferredDate={lastPreferredDate}
              toggleDrawer={toggleDrawer}
            />
          </MainCard>
        ))
      )}
    </>
  );
};

export default Future;
