import { Grid, Stack } from "@mui/material";
import TypoGraphy from "../../common/Typography";
import "./style.scss";

interface Props {
  title?: string;
  message?: string;
  Icon?: React.ReactNode;
  image?: string;
  errorIcon?: boolean;
  successIcon?: boolean;
}

const MeetRequirement: React.FC<Props> = ({
  message,
  title,
  Icon,
  image,
  errorIcon,
  successIcon,
}) => {
  return (
    <Grid xs={12} item>
      <Stack
        direction={"column"}
        alignContent={"flex-start"}
        alignItems={"start"}
        className={
          errorIcon
            ? "statement error"
            : successIcon
              ? "statement success"
              : "statement"
        }
      >
        <Stack direction={"row"} className="title">
          <Stack>{errorIcon ? <img src={image} alt={"Error"} /> : Icon}</Stack>
          <Stack>
            <TypoGraphy variant="body1" typeClass={"semi-bold-font"}>
              {title}
            </TypoGraphy>
          </Stack>
        </Stack>
        <Stack>
          <TypoGraphy variant="body1" typeClass={"message"}>
            {message}
          </TypoGraphy>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default MeetRequirement;
