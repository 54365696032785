import React from "react";
import MainCard from "../../../common/Card";
import RequestCardContent from "./RequestCardContent";
import LoadUI from "../../../shared/NoData/LoadUI";
import TypoGraphy from "../../../common/Typography";
import NoAvailability from "../../../shared/NoData/NoAvailability";
import { useTranslation } from "react-i18next";

interface Props {
  requestsData: any[];
  loading: boolean;
}

const Request: React.FC<Props> = ({ requestsData, loading }) => {
  const { t: translate } = useTranslation();

  return (
    <>
      {loading ? (
        <LoadUI />
      ) : (
        <>
          <TypoGraphy
            variant="h2"
            typeClass="card-header-title request-title"
            aria-describedby={translate("MY_AVAILABILITY_REQUESTS")}
          >
            {translate("MY_AVAILABILITY_REQUESTS")}
          </TypoGraphy>

          {requestsData?.length === 0 || typeof requestsData === "undefined" ? (
            <NoAvailability
              className="mt-1 blank-grid avail-request"
              message={translate(
                "YOU_HAVE_NO_AVAILABILITY_REQUESTS_CURRENTLY_LISTED",
              )}
            />
          ) : (
            <MainCard cardClass="request-card" contentClass="card-content">
              <RequestCardContent requestsData={requestsData} />
            </MainCard>
          )}
        </>
      )}
    </>
  );
};

export default Request;
