import { createSlice } from "@reduxjs/toolkit";
import { getUserStoreData, getUserStoreHours } from "./action";

interface UserStoreState {
  storeData: any;
  loading: boolean;
  error: string | null;
  success: boolean;
}

interface UserStoreHoursState {
  storeOperatingHours: OperatingHours[];
  loading: boolean;
  error: string | null;
  success: boolean;
}

export interface OperatingHours {
  dayOfWeek: string;
  storeOpenTime: string;
  storeCloseTime: string;
  staffOpenTime: string;
  staffCloseTime: string;
  staffOpenHours: string;
  open24Hours: boolean;
  openToBusiness: boolean;
}

const initialState: UserStoreState = {
  storeData: {},
  loading: false,
  error: null,
  success: false,
};

const initialHoursState: UserStoreHoursState = {
  storeOperatingHours: [],
  loading: false,
  error: null,
  success: false,
};

const userStoreSlice = createSlice({
  name: "userStore",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserStoreData.pending, (state: UserStoreState) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(
        getUserStoreData.fulfilled,
        (state: UserStoreState, action: any) => {
          state.loading = false;
          state.storeData = action.payload;
          state.error = null;
          state.success = true;
        },
      )
      .addCase(
        getUserStoreData.rejected,
        (state: UserStoreState, action: any) => {
          state.loading = false;
          state.error = action.error.message || "Failed to fetch user details";
          state.success = false;
        },
      );
  },
});

export const userStoreHoursSlice = createSlice({
  name: "userStoreHours",
  initialState: initialHoursState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserStoreHours.pending, (state: UserStoreHoursState) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(
        getUserStoreHours.fulfilled,
        (state: UserStoreHoursState, action: any) => {
          state.loading = false;
          state.storeOperatingHours = action.payload.data.storeOperatingHours;
          state.error = null;
          state.success = true;
        },
      )
      .addCase(
        getUserStoreHours.rejected,
        (state: UserStoreHoursState, action: any) => {
          state.loading = false;
          state.error =
            action.error.message || "Failed to fetch user store hours";
          state.success = false;
        },
      );
  },
});

export default userStoreSlice.reducer;
