import MainCard from "../../../common/Card";
import CardContent from "../CardContent";
import TypoGraphy from "../../../common/Typography";
import { Stack } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NoAvailability from "../../../shared/NoData/NoAvailability";

interface Props {
  current?: boolean;
  currentData?: any;
}

const Current: React.FC<Props> = ({ current, currentData }) => {
  const [expandedId, setExpandedId] = useState<number | null>(null);
  const [selectedWeek, setSelectedWeek] = useState<number | null>(null);
  const { t: translate } = useTranslation();
  const currentAvailabilityArray = [currentData];

  const handleAccordionChange = (id: number) => {
    setExpandedId((prevId) => (prevId === id ? null : id));
  };
  const activeWeek = () => {
    const numberOfWeeks = currentAvailabilityArray[0]?.numberOfWeeks;
    const startDate = new Date(currentAvailabilityArray[0]?.startDate);
    const endDate = currentAvailabilityArray[0]?.endDate
      ? new Date(currentAvailabilityArray[0]?.endDate)
      : new Date();
    const today = new Date();

    const weeks: { weekNumber: number; start: Date; end: Date }[] = [];
    let weeksCount = 0;
    let currentWeekNumber = 1;

    const currentWeekStart = new Date(startDate);
    currentWeekStart.setDate(
      currentWeekStart?.getDate() - (currentWeekStart?.getDay() - 1),
    );

    while (currentWeekStart <= endDate) {
      const weekEnd = new Date(currentWeekStart);
      weekEnd?.setDate(weekEnd?.getDate() + 6);

      weeksCount++;
      weeks?.push({
        weekNumber: currentWeekNumber,
        start: new Date(currentWeekStart),
        end: new Date(weekEnd),
      });

      if (weeksCount % numberOfWeeks === 0) {
        currentWeekNumber = 1;
      } else {
        currentWeekNumber++;
      }

      currentWeekStart?.setDate(currentWeekStart?.getDate() + 7);
    }

    const currentWeek = weeks?.find(
      (week) => today >= week?.start && today <= week?.end,
    );
    if (currentWeek) {
      setSelectedWeek(currentWeek?.weekNumber);
    }
    return weeks;
  };

  useEffect(() => {
    activeWeek();
  }, [currentAvailabilityArray]);

  return (
    <>
      <TypoGraphy
        variant="h2"
        typeClass="card-header-title"
        aria-describedby={translate("MY_CURRENT_AVAILABILITY")}
      >
        {translate("MY_CURRENT_AVAILABILITY")}
      </TypoGraphy>

      {!currentAvailabilityArray ||
      (currentAvailabilityArray.length === 1 &&
        (currentAvailabilityArray[0] === undefined ||
          currentAvailabilityArray[0] === null ||
          Object.keys(currentAvailabilityArray[0]).length === 0)) ? (
        <NoAvailability
          className="mt-1 blank-grid current-availability"
          message={translate("NO_AVAILABILITY")}
        />
      ) : (
        currentAvailabilityArray.map((weekData: any) => (
          <Stack key={weekData?.id}>
            <TypoGraphy
              variant="h3"
              typeClass="week-header-title"
              aria-describedby={`Week ${weekData?.weekNumber}`}
            ></TypoGraphy>
            {weekData?.weeklyAvailabilityResponseList?.map(
              (availData: any, index: number, array: any[]) => (
                <Fragment key={availData?.weekNumber}>
                  <MainCard
                    cardClass={"main-card"}
                    contentClass={
                      weekData?.weeklyAvailabilityResponseList?.length >= 2
                        ? selectedWeek === availData?.weekNumber
                          ? "card-content current-card available-card"
                          : "card-current-content current-card-dash available-card-dashed"
                        : "card-content current-card available-card"
                    }
                  >
                    <CardContent
                      genAvailData={availData?.generalAvailability}
                      endDate={weekData?.endDate}
                      startDate={weekData?.startDate}
                      current={current}
                      weekNumber={availData?.weekNumber}
                      expandedId={expandedId}
                      onAccordionChange={handleAccordionChange}
                    />
                  </MainCard>
                  {index < array.length - 1 && (
                    <Stack className="vertical-dotted-lines"></Stack>
                  )}
                </Fragment>
              ),
            )}
          </Stack>
        ))
      )}
    </>
  );
};

export default Current;
