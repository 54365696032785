import React, { useEffect, useRef, useState } from "react";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getMonthTranslations } from "../../../utils/getDays";
import TypoGraphy from "../../common/Typography";
import { formatDateString } from "../../../utils/durationCalculate";
import "./style.scss";

interface Props {
  startDate?: string;
  endDate?: string;
  onClick?: () => void;
  current?: boolean;
}

const DateHeader: React.FC<Props> = ({
  startDate,
  endDate,
  onClick,
  current,
}) => {
  const { t: translate, i18n } = useTranslation();
  const startDateRef = useRef<any>(null);
  const endDateRef = useRef<any>(null);
  const [startWidth, setStartWidth] = useState<number>(0);
  const [endWidth, setEndWidth] = useState<number>(0);
  const [dotWidth, setDotWidth] = useState<string>("");
  const { isToday, getMonthTranslation } = getMonthTranslations();

  useEffect(() => {
    handleDateWidth();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleDateWidth);
    handleDateWidth();
    if (startWidth && endWidth) {
      const width = `calc(100% - ${startWidth + endWidth}px)`;
      setDotWidth(width);
    }
  }, [startWidth, endWidth]);

  const handleDateWidth = () => {
    if (startDateRef.current) {
      setStartWidth(startDateRef.current.offsetWidth);
    }

    if (endDateRef.current) {
      setEndWidth(endDateRef.current.offsetWidth);
    }
  };

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      className="date-header"
      onClick={onClick}
    >
      <Stack ref={startDateRef} direction={"row"}>
        <TypoGraphy typeClass="light-font" variant="body1">
          {translate("START")}:
        </TypoGraphy>
        <TypoGraphy variant="body1" typeClass="semi-bold-font">
          {current
            ? translate("TODAY")
            : formatDateString(
                false,
                startDate,
                isToday,
                getMonthTranslation,
                translate,
                i18n.language,
              )}
        </TypoGraphy>
      </Stack>

      <Stack
        direction={"row"}
        style={{ width: dotWidth }}
        className="dot-stack"
      ></Stack>

      <Stack direction={"row"} ref={endDateRef}>
        <TypoGraphy typeClass="light-font" variant="body1" fontWeight="bold">
          {translate("END")}:
        </TypoGraphy>
        <TypoGraphy variant="body1" typeClass="semi-bold-font">
          {formatDateString(
            true,
            endDate,
            isToday,
            getMonthTranslation,
            translate,
            i18n.language,
          )}
        </TypoGraphy>
      </Stack>
    </Stack>
  );
};

export default DateHeader;
