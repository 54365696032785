import { Grid, IconButton } from "@mui/material";
import TypoGraphy from "../../../common/Typography";
import edit from "../../../../styles/assets/svg/edit.svg";
import { useContext } from "react";
import { AnalyticsContext } from "../../../../analytics";
import { useTranslation } from "react-i18next";

interface Props {
  nextStep: boolean;
  stepTitle?: string;
  setNextStep: (nextStep: boolean) => void;
  stepClass?: string;
  step1?: boolean;
  isStartDateExist?: boolean;
  isRequestSubmitted: boolean;
}

const Step: React.FC<Props> = ({
  nextStep,
  stepTitle,
  setNextStep,
  stepClass,
  step1,
  isStartDateExist,
  isRequestSubmitted,
}) => {
  const analytics = useContext(AnalyticsContext);
  const { t: translate } = useTranslation();

  return (
    <>
      <Grid xs={8} item>
        <TypoGraphy variant="h2" component={"h2"} typeClass={stepClass}>
          {stepTitle}
        </TypoGraphy>
      </Grid>
      <Grid xs={4} item textAlign={"right"}>
        {nextStep && step1 && !isStartDateExist && !isRequestSubmitted && (
          <IconButton
            onClick={() => {
              analytics?.trackEvent({
                name: `Edit button click`,
                properties: {
                  page: "Preferences",
                  workflow: "Create",
                  component: "Step",
                },
              });
              setNextStep(!nextStep);
            }}
            className={"edit-btn"}
          >
            <img
              src={edit}
              alt={edit}
              aria-label={translate("EDIT_PREFERENCES_DATE")}
            />
          </IconButton>
        )}
      </Grid>
    </>
  );
};

export default Step;
