import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./style.scss";

interface LoaderProps {
  fullScreen?: boolean;
  size?: string;
}

const Loader: React.FC<LoaderProps> = ({ fullScreen = false, size }) => {
  return (
    <div className={fullScreen ? "loader fullScreen" : "loader"}>
      <CircularProgress size={size ?? "3rem"} />
    </div>
  );
};

export default Loader;
