import { createSlice } from "@reduxjs/toolkit";
import {
  createPreference,
  getPreferences,
  getPreferencesAttribute,
  updatePreference,
} from "./action";
import { PreferItem } from "../../components/pages/Preferences/model";

export interface preferencesState {
  preferencesData: any;
  loading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: preferencesState = {
  preferencesData: [],
  loading: false,
  error: null,
  success: false,
};
export interface preferencesAttributeState {
  preferredHours: any;
  loading: boolean;
  error: string | null;
  success: boolean;
}

const initialAttributeState: preferencesAttributeState = {
  preferredHours: [],
  loading: false,
  error: null,
  success: false,
};

export interface preferenceItemState {
  preferenceItem: PreferItem;
  loading: boolean;
  error: string | null;
  success: boolean;
}

export const preferencesSlice = createSlice({
  name: "preferences",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPreferences.pending, (state: preferencesState) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(
        getPreferences.fulfilled,
        (state: preferencesState, action: any) => {
          state.loading = false;
          state.preferencesData = action.payload.data;
          state.success = action.payload.success;
        },
      )
      .addCase(
        getPreferences.rejected,
        (state: preferencesState, action: any) => {
          state.loading = false;
          state.error = action.payload;
          state.success = false;
        },
      )

      .addCase(createPreference.pending, (state: preferencesState) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(
        createPreference.fulfilled,
        (state: preferencesState, action: any) => {
          state.loading = false;
          state.preferencesData = action.payload.data;
          state.success = true;
        },
      )
      .addCase(
        createPreference.rejected,
        (state: preferencesState, action: any) => {
          state.loading = false;
          state.error = action.payload;
          state.success = false;
        },
      )

      .addCase(updatePreference.pending, (state: preferencesState) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(
        updatePreference.fulfilled,
        (state: preferencesState, action: any) => {
          state.loading = false;
          state.preferencesData = action.payload.data;
          state.success = true;
        },
      )
      .addCase(
        updatePreference.rejected,
        (state: preferencesState, action: any) => {
          state.loading = false;
          state.error = action.payload;
          state.success = false;
        },
      );
  },
});

export const preferencesAttributeSlice = createSlice({
  name: "preferencesAttribute",
  initialState: initialAttributeState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        getPreferencesAttribute.pending,
        (state: preferencesAttributeState) => {
          state.loading = true;
          state.success = false;
        },
      )
      .addCase(
        getPreferencesAttribute.fulfilled,
        (state: preferencesAttributeState, action: any) => {
          state.loading = false;
          state.preferredHours = action.payload.data;
          state.success = true;
        },
      )
      .addCase(
        getPreferencesAttribute.rejected,
        (state: preferencesAttributeState, action: any) => {
          state.loading = false;
          state.error = action.payload;
          state.success = false;
        },
      );
  },
});

export default preferencesSlice.reducer;
