interface RequestOptions {
  method: string;
  headers?: Headers;
  body?: string | FormData;
}

class HttpInterceptor {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  private async handleRequest(
    url: string,
    options: RequestOptions,
  ): Promise<Response> {
    const empId = localStorage.getItem("userEmployeeId") ?? "";
    let tokenData = localStorage.getItem("ROCP_idToken") ?? "";
    tokenData = tokenData.replace(/^"(.*)"$/, "$1");

    const userAuthData = {
      employeeId: empId?.length !== 0 && empId,
    };

    const authObject = "employeeId: " + userAuthData.employeeId;

    const jsonString = JSON.stringify(authObject);
    const base64String = btoa(jsonString);

    const apiUrl = `${this.baseUrl}${url}`;
    const defaultHeaders = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "auth-token":
        process.env.REACT_APP_IS_SSO_ENABLED === "true"
          ? tokenData
          : base64String,
    });

    const requestOptions: RequestOptions = {
      ...options,
      headers: new Headers(options.headers ?? defaultHeaders),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);

      if (!response.ok) {
        console.error(`Request failed with status ${response.status}`);
      }

      return response;
    } catch (error: any) {
      console.error(error);
      throw new Error(`Request failed: ${error.message}`);
    }
  }

  public async get(url: string): Promise<any> {
    const response = await this.handleRequest(url, { method: "GET" });
    return await response.json();
  }

  public async post(url: string, data: any): Promise<any> {
    const response = await this.handleRequest(url, {
      method: "POST",
      body: JSON.stringify(data),
    });
    return await response.json();
  }

  public async put(url: string, data: any): Promise<any> {
    const response = await this.handleRequest(url, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return await response.json();
  }

  public async delete(url: string): Promise<void> {
    const response = await this.handleRequest(url, { method: "DELETE" });
    if (response.status !== 204) {
      throw new Error(`Delete request failed with status ${response.status}`);
    }
  }
}

export default HttpInterceptor;
