import React, { ErrorInfo } from "react";
import { Stack } from "@mui/material";
import { withTranslation } from "react-i18next";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import Typography from "../../common/Typography";
import "./style.scss";
interface Props {
  // Class components like ErrorBoundary can't use React Context, so analytics is passed as a prop.
  analytics?: ApplicationInsights;
  children?: React.ReactNode;
  t: any;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const { t: translate, analytics } = this.props;

    analytics?.trackException({ exception: error }, info);

    console.log(translate("ERROR_CAUGHT"), error, info);
  }

  render() {
    const { t: translate } = this.props;

    if (this.state.hasError) {
      return (
        <Stack
          className="error-page"
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Stack>
            <Typography
              variant={"h1"}
              component={"h1"}
              aria-label={translate("ERROR_OCCURRED")}
            >
              {translate("OOPS")}
            </Typography>
            <Typography
              variant={"h4"}
              component={"h4"}
              aria-label={translate("ERROR_MESSAGE")}
            >
              {translate("SOMETHING_WRONG")}
            </Typography>
          </Stack>
        </Stack>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
