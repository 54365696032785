import { Grid, Stack } from "@mui/material";
import "../../../../shared/UserProfile/styles.scss";
import CustomDialog from "../../../../common/Dialog";
import DateHeader from "../../../../shared/DateHeader/DateHeader";
import { useTranslation } from "react-i18next";

interface Props {
  openModal?: any;
  closeModal?: () => void;
  startDate?: string;
  endDate?: string;
  handleDelete: () => void;
}

const DeleteConfirmModal: React.FC<Props> = ({
  openModal,
  closeModal,
  handleDelete,
  startDate,
  endDate,
}) => {
  const { t: translate } = useTranslation();
  return (
    <>
      <CustomDialog
        title={translate("CONFIRM_CANCELLATION")}
        dialogClass="confirm-dialog"
        open={openModal}
        onCancel={closeModal}
        onConfirm={handleDelete}
        confirmText={translate("CONFIRM")}
        cancelText={translate("CLOSE")}
        confirmBtnClass={"secondary-btn"}
        cancelBtnClass={"close-btn"}
        isCancelIcon={true}
        contentClass="dialog-content"
      >
        <Grid item xs={12}>
          <Stack>
            <DateHeader startDate={startDate} endDate={endDate} />
          </Stack>
        </Grid>
      </CustomDialog>
    </>
  );
};

export default DeleteConfirmModal;
