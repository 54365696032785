import { Grid, Stack } from "@mui/material";
import React from "react";
import "./style.scss";
import TypoGraphy from "../../common/Typography";

interface NoDataProps {
  message: string;
}

const NoData: React.FC<NoDataProps> = ({ message }) => {
  return (
    <Grid
      xs={12}
      item
      container
      className="noData-container"
      direction={"row"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack>
        <TypoGraphy variant="body1">{message}</TypoGraphy>
      </Stack>
    </Grid>
  );
};

export default NoData;
