import { Grid, Stack } from "@mui/material";
import TypoGraphy from "../../../common/Typography";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/store";

const RequestCondition: React.FC = () => {
  const { t: translate } = useTranslation();
  const { storeData } = useAppSelector((state: any) => state?.userStoreData);

  return (
    <Grid item xs={12}>
      <Stack className={"request-condition"} direction={"row"}>
        <TypoGraphy variant="body1" id="minimumValueText">
          {`${translate("AVAILABILITY_MINIMUM_HRS_MSG")} ${storeData.minAvailabilityHours} ${translate("HRS/WK")}`}
        </TypoGraphy>
      </Stack>
    </Grid>
  );
};

export default RequestCondition;
