import { Grid, Stack } from "@mui/material";
import TypoGraphy from "../../../common/Typography";
import CustomList from "../../../common/List";
import { CommentType } from "../constants";
import {
  formatDateStringWithoutToday,
  formatTimeForNewRequestTime,
} from "../../../../utils/durationCalculate";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CommentCard from "./CommentCard";
import { DENIED, END_DATE_INCLUDE_DOT_1_Value } from "../../../GlobalConstants";
import { getMonthTranslations } from "../../../../utils/getDays";

interface Props {
  generalAvailability?: any;
  comments?: CommentType[];
  requestStatus?: string;
}

const RequestAccContent: React.FC<Props> = ({
  generalAvailability,
  comments,
  requestStatus,
}) => {
  const { t: translate, i18n } = useTranslation();
  const { isToday, getMonthTranslation } = getMonthTranslations();

  return (
    <>
      <Grid item xs={12} className="request-container">
        <CustomList
          listClass={"schedule-list"}
          items={generalAvailability?.map((item: any) => {
            const generalAvailabilityTimings =
              item.generalAvailabilityTimings || [];

            const totalWorkingHours = generalAvailabilityTimings?.reduce(
              (total: number, eachTiming: any) => {
                if (eachTiming?.workingHours) {
                  const [hours, minutes] = eachTiming.workingHours
                    .split(":")
                    .map(Number);
                  total += hours + minutes / 60;
                }
                return total;
              },
              0,
            );

            return (
              <Grid
                container
                alignItems="stretch"
                flexDirection={"row"}
                key={item?.id}
                item
              >
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  className={
                    generalAvailabilityTimings &&
                    generalAvailabilityTimings?.length > 0
                      ? "left"
                      : "absent-day left"
                  }
                >
                  <TypoGraphy
                    variant="body1"
                    typeClass={"semi-bold-font"}
                    ariaLabel={item.dayOfWeek}
                  >
                    {translate(item.dayOfWeek).substring(0, 3)}
                  </TypoGraphy>
                </Stack>

                <Stack
                  className="right"
                  justifyContent={"space-between"}
                  direction={"row"}
                  alignItems={"center"}
                >
                  <Stack className="clubTime">
                    {generalAvailabilityTimings.length > 0 ? (
                      generalAvailabilityTimings.map((item: any) => (
                        <Stack key={item.id} alignItems={"center"}>
                          {item?.allDayAvailable === true ? (
                            <TypoGraphy
                              variant="body1"
                              typeClass="all-day semi-bold-font"
                            >
                              {translate("AVAILABLE_ALL_DAY")}
                            </TypoGraphy>
                          ) : (
                            <TypoGraphy
                              variant="body1"
                              typeClass={"semi-bold-font"}
                              key={item.id}
                            >
                              {moment(item?.startTimeOffset, "HH:mm:ss").format(
                                "hh:mm A",
                              )}{" "}
                              -{" "}
                              {item?.endTimeOffset?.startsWith("1.")
                                ? moment(
                                    END_DATE_INCLUDE_DOT_1_Value,
                                    "hh:mm A",
                                  )
                                    .add(
                                      parseInt(
                                        item?.endTimeOffset
                                          .split(".")[1]
                                          .split(":")[0],
                                      ),
                                      "hours",
                                    )
                                    .format("hh:mm A")
                                : moment(
                                    item?.endTimeOffset,
                                    "HH:mm:ss",
                                  ).format("hh:mm A")}
                            </TypoGraphy>
                          )}
                        </Stack>
                      ))
                    ) : (
                      <TypoGraphy
                        variant="body1"
                        typeClass={"null-time semi-bold-font"}
                      >
                        {translate("NOT_AVAILABLE")}
                      </TypoGraphy>
                    )}
                  </Stack>
                  <Stack>
                    {totalWorkingHours !== 0 ? (
                      <TypoGraphy variant="body1" typeClass={"duration"}>
                        {formatTimeForNewRequestTime(
                          totalWorkingHours.toFixed(2),
                        )}{" "}
                        {translate("HRS")}
                      </TypoGraphy>
                    ) : (
                      <TypoGraphy variant="body1" typeClass={"duration"}>
                        {"-"}
                      </TypoGraphy>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            );
          })}
        />
      </Grid>

      <Grid item xs={12}>
        {comments?.[0]?.comment && (
          <>
            <Stack>
              <TypoGraphy variant="h2">{translate("COMMENTS")}</TypoGraphy>
            </Stack>
            <Grid item xs={12} className="request-container">
              <CommentCard
                CommentClass="user-msg-container"
                CommentData={comments[0]}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Grid item xs={12} container className="submit-info">
        <Grid item xs={6}>
          <Stack>
            <TypoGraphy variant="body1" typeClass={"light-font"}>
              {translate("SUBMITTED")}
            </TypoGraphy>
          </Stack>
          <Stack>
            <TypoGraphy variant="body1" typeClass={"semi-bold-font"}>
              {comments && (
                <>
                  {formatDateStringWithoutToday(
                    comments[0]?.requestLastUpdatedDate
                      ? comments[0]?.requestLastUpdatedDate
                      : "",
                    isToday,
                    getMonthTranslation,
                    translate,
                    i18n.language,
                  )}
                </>
              )}
            </TypoGraphy>
          </Stack>
        </Grid>

        {requestStatus === DENIED && (
          <Grid item xs={6} textAlign={"right"}>
            <Stack>
              <TypoGraphy variant="body1" typeClass={"light-font"}>
                {translate("APPROVER")}
              </TypoGraphy>
            </Stack>
            <Stack>
              <TypoGraphy variant="body1" typeClass={"semi-bold-font"}>
                {comments?.[0]?.employeeName?.split(" ")[1]}
                {", " + translate("MANAGER")}
              </TypoGraphy>
            </Stack>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default RequestAccContent;
