import { createAsyncThunk } from "@reduxjs/toolkit";
import availabilityService from "../../services/availabilityServices";
import ApiService from "../../services/commonServices/apiService";
const AvailService: availabilityService = new availabilityService(ApiService);
export const getAvailability = createAsyncThunk(
  `availability/getData`,
  async () => {
    const employeeId = localStorage.getItem("userEmployeeId");
    const res = await AvailService.getData(employeeId);
    return res;
  },
);

export const createAvailability = createAsyncThunk(
  `availability/createData`,
  async (input) => {
    const res = await AvailService.createData(input);
    return res;
  },
);
