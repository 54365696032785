import { Grid } from "@mui/material";
import TypoGraphy from "../../common/Typography";
import { useTranslation } from "react-i18next";

interface Props {
  DataItem: any;
}

const PreferItem: React.FC<Props> = ({ DataItem }) => {
  const { t: translate } = useTranslation();
  return (
    <>
      <Grid
        item
        xs={4}
        container
        className="PreferredItem"
        direction={"column"}
        tabIndex={-1}
      >
        <TypoGraphy
          typeClass={"semi-bold-font"}
          variant={"body1"}
          tabIndex={0}
          aria-label={DataItem.label}
        >
          {DataItem.label}
        </TypoGraphy>
        <TypoGraphy
          typeClass="semi-bold-font"
          variant={"h4"}
          tabIndex={0}
          aria-label={DataItem.value}
        >
          {DataItem.value}
        </TypoGraphy>
        <TypoGraphy
          typeClass="light-font"
          variant={"body1"}
          tabIndex={0}
          aria-label={`${translate("HRS")} per ${translate("WEEK")}`}
        >
          {translate("HRS")}/{translate("WEEK")}
        </TypoGraphy>
      </Grid>
    </>
  );
};

export default PreferItem;
