import { Grid, Stack } from "@mui/material";
import CustomList from "../../common/List";
import { formatTimeForNewRequestTime } from "../../../utils/durationCalculate";
import TypoGraphy from "../../common/Typography";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Availability } from "./constants";
import React from "react";
import { END_DATE_INCLUDE_DOT_1_Value } from "../../GlobalConstants";

interface Props {
  availData: Availability[];
}

const AccContent: React.FC<Props> = ({ availData }) => {
  const { t: translate } = useTranslation();
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12} className="avail-container">
          <Stack>
            <CustomList
              listClass={"schedule-list"}
              items={availData?.map((availItem: Availability) => {
                const generalAvailabilityTimings =
                  availItem.generalAvailabilityTimings ?? [];
                const totalWorkingHours = generalAvailabilityTimings?.reduce(
                  (total: number, eachTiming: any) => {
                    if (eachTiming?.workingHours) {
                      const [hours, minutes] = eachTiming.workingHours
                        .split(":")
                        .map(Number);
                      total += hours + minutes / 60;
                    }
                    return total;
                  },
                  0,
                );

                return (
                  <Grid
                    container
                    alignItems="stretch"
                    flexDirection={"row"}
                    key={availItem?.dayOfWeek}
                    item
                  >
                    <Stack
                      alignItems={"center"}
                      justifyContent={"center"}
                      className={
                        generalAvailabilityTimings &&
                        generalAvailabilityTimings?.length > 0
                          ? "left"
                          : "absent-day left"
                      }
                    >
                      <TypoGraphy
                        variant="body1"
                        typeClass={"semi-bold-font"}
                        ariaLabel={availItem.dayOfWeek}
                      >
                        {translate(availItem.dayOfWeek).substring(0, 3)}
                      </TypoGraphy>
                    </Stack>
                    <Stack
                      className="right"
                      justifyContent={"space-between"}
                      direction={"row"}
                      alignItems={"center"}
                    >
                      <Stack className="clubTime">
                        {generalAvailabilityTimings.length > 0 ? (
                          generalAvailabilityTimings?.map((item) => (
                            <Stack key={item.id} alignItems={"center"}>
                              {item?.allDayAvailable === true ? (
                                <TypoGraphy
                                  variant="body1"
                                  typeClass="all-day semi-bold-font"
                                >
                                  {translate("AVAILABLE_ALL_DAY")}
                                </TypoGraphy>
                              ) : (
                                <TypoGraphy
                                  variant="body1"
                                  typeClass={"semi-bold-font"}
                                  key={item.id}
                                >
                                  {moment(
                                    item?.startTimeOffset,
                                    "HH:mm:ss",
                                  ).format("hh:mm A")}{" "}
                                  -{" "}
                                  {item?.endTimeOffset?.startsWith("1.")
                                    ? moment(
                                        END_DATE_INCLUDE_DOT_1_Value,
                                        "hh:mm A",
                                      )
                                        .add(
                                          parseInt(
                                            item?.endTimeOffset
                                              .split(".")[1]
                                              .split(":")[0],
                                          ),
                                          "hours",
                                        )
                                        .format("hh:mm A")
                                    : moment(
                                        item?.endTimeOffset,
                                        "HH:mm:ss",
                                      ).format("hh:mm A")}
                                </TypoGraphy>
                              )}
                            </Stack>
                          ))
                        ) : (
                          <TypoGraphy
                            variant="body1"
                            typeClass={"null-time semi-bold-font"}
                          >
                            {translate("NOT_AVAILABLE")}
                          </TypoGraphy>
                        )}
                      </Stack>

                      <Stack>
                        {totalWorkingHours !== 0 ? (
                          <TypoGraphy variant="body1" typeClass={"duration"}>
                            {formatTimeForNewRequestTime(
                              totalWorkingHours.toFixed(2),
                            )}{" "}
                            {translate("HRS")}
                          </TypoGraphy>
                        ) : (
                          <TypoGraphy variant="body1" typeClass={"duration"}>
                            {"-"}
                          </TypoGraphy>
                        )}
                      </Stack>
                    </Stack>
                  </Grid>
                );
              })}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default AccContent;
