import { t as translate } from "i18next";
import moment from "moment";

type MonthTranslations = Record<number, { full: string }>;

export const getMonthTranslations = () => {
  const monthTranslations: MonthTranslations = {
    0: { full: translate("JANUARY") },
    1: { full: translate("FEBRUARY") },
    2: { full: translate("MARCH") },
    3: { full: translate("APRIL") },
    4: { full: translate("MAY") },
    5: { full: translate("JUNE") },
    6: { full: translate("JULY") },
    7: { full: translate("AUGUST") },
    8: { full: translate("SEPTEMBER") },
    9: { full: translate("OCTOBER") },
    10: { full: translate("NOVEMBER") },
    11: { full: translate("DECEMBER") },
  };

  return {
    isToday: (newDate: string): boolean => {
      const today = moment().utc();
      const newDateObj = moment(newDate).utc();
      return (
        today.year() === newDateObj.year() &&
        today.month() === newDateObj.month() &&
        today.format() === newDateObj.format()
      );
    },
    remainingDays: (newDate: string | undefined): number => {
      if (!newDate) {
        return NaN;
      }

      const today = moment().utc();
      const targetDate = moment(newDate).utc();
      const remainTime = targetDate.diff(today);
      return Math.ceil(remainTime / (1000 * 60 * 60 * 24));
    },
    getMonthTranslation: (monthIndex: number): string => {
      const fullMonth = monthTranslations[monthIndex]?.full;
      return fullMonth ? fullMonth.substring(0, 3) : "";
    },
  };
};
