interface ErrorResponse {
  response: {
    status: number;
  };
}

export const handleFetchError = (error: ErrorResponse): string => {
  if (!error) {
    return "Network error occurred. Please try again.";
  }

  const status = error.response.status;
  switch (status) {
    case 400:
      return "Bad request. Please check your request.";
    case 401:
      return "Unauthorized. Please login again.";
    case 404:
      return "Resource not found.";
    case 500:
      return "Unable to fetch data at this moment. Please try again later.";
    default:
      return `Unexpected error: ${status}`;
  }
};

// TODO: Standardize console.error handling with https://sim.starbucks.com/browse/TWP-49873.
export const handleLocalError = (errorLocation: string, error: Error) => {
  console.error(`An error has occurred in ${errorLocation}:`, error);
};
