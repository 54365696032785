import { Grid, Stack } from "@mui/material";
import TypoGraphy from "../../common/Typography";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import CustomIcon from "../../common/Icon";
import { WarningRounded } from "@mui/icons-material";
import { useContext } from "react";
import { AnalyticsContext, PageType } from "../../../analytics";

const ErrorPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const analytics = useContext(AnalyticsContext);

  analytics?.trackPageView({
    name: "Error Page",
    pageType: PageType.Document,
  });

  return (
    <Grid
      xs={12}
      item
      container
      justifyContent={"center"}
      alignItems={"center"}
      className="errorPage"
    >
      <Grid
        xs={12}
        className="access-denied-content"
        justifyContent={"center"}
        alignItems={"center"}
        container
        item
      >
        <Stack alignItems={"center"} spacing={1}>
          <CustomIcon IconComponent={WarningRounded} />
          <TypoGraphy variant="h1" typeClass={"semi-bold-font"}>
            {translate("SYSTEM_ERROR")}
          </TypoGraphy>
          <TypoGraphy variant="body1" typeClass={"regular-font"}>
            {translate("SYSTEM_ERROR_MESSAGE")}
          </TypoGraphy>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
