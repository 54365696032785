import { Grid, MenuItem, Select, Stack } from "@mui/material";
import TypoGraphy from "../../../common/Typography";
import CustomSwitch from "../../../common/AntSwitch";
import { useContext, useEffect, useState } from "react";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import CustomIconBtn from "../../../common/IconBtn";
import {
  convertTo24Hour,
  convertTo24HourEndTime,
} from "../../../../utils/durationCalculate";
import {
  DEFAULT_TIME,
  DEFAULT_TIME_FOR_ALL_DAY,
  DEFAULT_HOURS_FOR_ALL_DAY,
  DEFAULT_TIME_FOR_ALL_DAY_END_DATE,
  END_TIME_NOON,
  START_TIME_NOON,
  LAST_SELECTABLE_START_TIME,
  START_TIME,
  START_TIME_OPTIONS,
  END_TIME_OPTIONS,
  LAST_SELECTABLE_END_TIME,
} from "./Constant";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import { updateUpdatedDays } from "../../../../features/weekdaysReducer";
import { AnalyticsContext } from "../../../../analytics";
import { END_DATE_INCLUDE_DOT_1_Value } from "../../../GlobalConstants";

interface WeeklyDay {
  id: null | string;
  dayOfWeek: string;
  weekNumber: number;
  notAvailable?: boolean;
  generalAvailabilityTimings?:
    | {
        startTimeOffset: string | null;
        endTimeOffset: string | null;
        workingHours: null | string;
        allDayAvailable?: boolean;
        notAvailable?: boolean;
      }[]
    | null;
}

interface Props {
  dayName: string;
  weeklyDays: WeeklyDay[];
  setWeeklyDays: (weeklyDays: WeeklyDay[]) => void;
  updateWeeklyDays: (weeklyDays: WeeklyDay[]) => void;
  generalAvailabilityTimings: boolean;
  updateTotalHours: (hours: any) => void;
}

const MarkDay: React.FC<Props> = ({
  dayName,
  weeklyDays,
  setWeeklyDays,
  updateWeeklyDays,
  generalAvailabilityTimings,
  updateTotalHours,
}) => {
  const { t: translate } = useTranslation();
  const [times, setTimes] = useState<{ startTime: string; endTime: string }[]>([
    { startTime: DEFAULT_TIME, endTime: DEFAULT_TIME },
  ]);
  const [allDaySwitch, setAllDaySwitch] = useState<boolean>(false);
  const [notAvailableSwitch, setNotAvailableSwitch] = useState<boolean>(false);
  const [calculatedHours, setCalculatedHours] = useState<any>("0");
  const { availabilityData } = useAppSelector(
    (state: any) => state.availabilityAll,
  );
  const copyCurrentAvailability = useAppSelector(
    (state: any) => state.copyCurrentAvailability.copyCurrentAvailability,
  );
  const editPendingAvailability = useAppSelector(
    (state: any) => state.meta.editPendingAvailability,
  );
  const editPendingAvailabilityData = useAppSelector(
    (state: any) => state.meta.editPendingAvailabilityData,
  );
  const updatedWeekDaysStore = useAppSelector(
    (state: any) => state.updateWeekdays.updatedDays,
  );
  const editBtnDateHeaderFlag = useAppSelector(
    (state: any) => state.updateWeekdays.editNewAvailabilityRequest,
  );
  const { storeOperatingHours, success: storeHoursSuccess } = useAppSelector(
    (state: any) => state.storeHoursData,
  );
  const [updatedWeeklyDays, setUpdatedWeeklyDays] = useState<any[]>([]);
  const [disabledSlots, setDisabledSlots] = useState<number[]>([]);
  const dispatch = useAppDispatch();
  const startTimeArr: string[] = START_TIME_OPTIONS;
  const endTimeArr: string[] = END_TIME_OPTIONS;
  const analytics = useContext(AnalyticsContext);

  useEffect(() => {
    if (weeklyDays) {
      setUpdatedWeeklyDays(JSON.parse(JSON.stringify(weeklyDays)));
    }
  }, [weeklyDays]);

  useEffect(() => {
    calculateHours(times);
  }, [weeklyDays, times, allDaySwitch]);

  useEffect(() => {
    copyCurrentAvailabilityCalculateTotalHours();
  }, [updatedWeeklyDays, allDaySwitch, times]);

  const isEqual = (arr1: any, arr2: any) => {
    if (arr1?.length !== arr2?.length) {
      return false;
    }
    for (let i = 0; i < arr1?.length; i++) {
      if (
        arr1[i]?.generalAvailabilityTimings !==
        arr2[i]?.generalAvailabilityTimings
      ) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const currentAvailabilityData =
      availabilityData?.currentAvailability?.weeklyAvailabilityResponseList?.[0]
        ?.generalAvailability;
    if (copyCurrentAvailability) {
      clearStateData();
      //Start------this is for AllDayAvailable and NotAvailable switches -------

      const currentDayData = currentAvailabilityData?.find(
        (data: any) => data?.dayOfWeek === dayName?.toUpperCase(),
      );

      if (currentDayData?.generalAvailabilityTimings.length > 0) {
        currentDayData?.generalAvailabilityTimings?.forEach((timing: any) => {
          if (timing.allDayAvailable === true) {
            setAllDaySwitch(true);
          }
        });

        const timesFromCurrentData =
          currentDayData?.generalAvailabilityTimings?.map((timing: any) => ({
            startTime: timing.startTimeOffset,
            endTime: timing.endTimeOffset,
          }));

        if (timesFromCurrentData?.length > 1) {
          setDisabledSlots(
            Array.from(
              { length: timesFromCurrentData?.length - 1 },
              (_, index) => index,
            ),
          );
        } else {
          setDisabledSlots([]);
        }

        if (timesFromCurrentData && timesFromCurrentData?.length > 0) {
          setTimes(timesFromCurrentData);
        } else {
          // Set a default slot if no data is available
          setTimes([{ startTime: DEFAULT_TIME, endTime: DEFAULT_TIME }]);
        }
      } else if (currentDayData?.generalAvailabilityTimings.length === 0) {
        setNotAvailableSwitch(true);
      }
      //Start------this is for AllDayAvailable and NotAvailable switches -------
      const updatedWeeklyDays: any[] = [];
      currentAvailabilityData?.forEach(
        (currentDayData: {
          dayOfWeek: any;
          generalAvailabilityTimings: any;
        }) => {
          const { dayOfWeek, generalAvailabilityTimings } = currentDayData;
          const existingDayIndex = updatedWeeklyDays?.findIndex(
            (day) => day.dayOfWeek === dayOfWeek,
          );

          if (existingDayIndex !== -1) {
            updatedWeeklyDays[existingDayIndex] = {
              ...updatedWeeklyDays[existingDayIndex],
              generalAvailabilityTimings,
              notAvailable: generalAvailabilityTimings ? false : true,
            };
          } else {
            updatedWeeklyDays.push({
              id: null,
              dayOfWeek,
              weekNumber: 1,
              generalAvailabilityTimings,
              notAvailable: generalAvailabilityTimings ? false : true,
            });
          }
        },
      );

      setWeeklyDays(updatedWeeklyDays);
      updateWeeklyDays(updatedWeeklyDays);
      dispatch(updateUpdatedDays(updatedWeeklyDays));
    } else if (editPendingAvailability) {
      //Start------this is for AllDayAvailable and NotAvailable switches -------

      const editPendingData =
        editPendingAvailabilityData?.generalAvailability.find(
          (data: any) => data?.dayOfWeek === dayName?.toUpperCase(),
        );

      if (editPendingData?.generalAvailabilityTimings.length > 0) {
        editPendingData?.generalAvailabilityTimings?.forEach((timing: any) => {
          if (timing.allDayAvailable === true) {
            setAllDaySwitch(true);
          }
        });

        const timesFromCurrentData =
          editPendingData?.generalAvailabilityTimings?.map((timing: any) => ({
            startTime: timing.startTimeOffset,
            endTime: timing.endTimeOffset,
          }));

        if (timesFromCurrentData?.length > 1) {
          setDisabledSlots(
            Array.from(
              { length: timesFromCurrentData?.length - 1 },
              (_, index) => index,
            ),
          );
        } else {
          setDisabledSlots([]);
        }

        if (timesFromCurrentData && timesFromCurrentData?.length > 0) {
          setTimes(timesFromCurrentData);
        } else {
          // Set a default slot if no data is available
          setTimes([{ startTime: DEFAULT_TIME, endTime: DEFAULT_TIME }]);
        }
      } else if (editPendingData?.generalAvailabilityTimings.length === 0) {
        setNotAvailableSwitch(true);
      }
      //Start------this is for AllDayAvailable and NotAvailable switches -------
      const updatedWeeklyDays: any[] = [];
      editPendingAvailabilityData?.generalAvailability?.forEach(
        (currentDayData: {
          dayOfWeek: any;
          generalAvailabilityTimings: any;
        }) => {
          const { dayOfWeek, generalAvailabilityTimings } = currentDayData;
          const existingDayIndex = updatedWeeklyDays?.findIndex(
            (day) => day.dayOfWeek === dayOfWeek,
          );

          if (existingDayIndex !== -1) {
            updatedWeeklyDays[existingDayIndex] = {
              ...updatedWeeklyDays[existingDayIndex],
              generalAvailabilityTimings,
              notAvailable: generalAvailabilityTimings ? false : true,
            };
          } else {
            updatedWeeklyDays.push({
              id: null,
              dayOfWeek,
              weekNumber: 1,
              generalAvailabilityTimings,
              notAvailable: generalAvailabilityTimings ? false : true,
            });
          }
        },
      );

      setWeeklyDays(updatedWeeklyDays);
      updateWeeklyDays(updatedWeeklyDays);
      dispatch(updateUpdatedDays(updatedWeeklyDays));
    } else {
      const currentDayData: any = updatedWeekDaysStore?.find(
        (data: any) => data?.dayOfWeek === dayName?.toUpperCase(),
      );
      const timesFromCurrentData =
        currentDayData?.generalAvailabilityTimings?.map((timing: any) => ({
          startTime: timing.startTimeOffset,
          endTime: timing.endTimeOffset,
        }));

      if (timesFromCurrentData?.length > 1) {
        setDisabledSlots(
          Array.from(
            { length: timesFromCurrentData?.length - 1 },
            (_, index) => index,
          ),
        );
      } else {
        setDisabledSlots([]);
      }
      if (isEqual(updatedWeekDaysStore, currentAvailabilityData)) {
        clearStateData();
      }
    }
  }, [
    copyCurrentAvailability,
    generalAvailabilityTimings,
    availabilityData,
    editPendingAvailability,
  ]);

  useEffect(() => {
    const currentAvailabilityData = copyCurrentAvailability
      ? availabilityData?.currentAvailability
          ?.weeklyAvailabilityResponseList?.[0]?.generalAvailability
      : updatedWeekDaysStore;
    if (editBtnDateHeaderFlag && !copyCurrentAvailability) {
      if (updatedWeekDaysStore.length > 0) {
        const currentDayData = currentAvailabilityData?.find(
          (data: any) => data?.dayOfWeek === dayName?.toUpperCase(),
        );

        if (currentDayData?.generalAvailabilityTimings.length > 0) {
          currentDayData?.generalAvailabilityTimings?.forEach((timing: any) => {
            if (timing.allDayAvailable === true) {
              setAllDaySwitch(true);
            }
          });

          const timesFromCurrentData =
            currentDayData?.generalAvailabilityTimings?.map((timing: any) => ({
              startTime: timing.startTimeOffset,
              endTime: timing.endTimeOffset,
            }));

          if (timesFromCurrentData && timesFromCurrentData?.length > 0) {
            setTimes(timesFromCurrentData);
          } else {
            setTimes([{ startTime: DEFAULT_TIME, endTime: DEFAULT_TIME }]);
          }
        } else {
          if (currentDayData?.generalAvailabilityTimings.length === 0) {
            setNotAvailableSwitch(true);
          }
        }

        const updatedWeeklyDays: any[] = [];

        currentAvailabilityData?.forEach(
          (currentDayData: {
            dayOfWeek: any;
            generalAvailabilityTimings: any;
          }) => {
            const { dayOfWeek, generalAvailabilityTimings } = currentDayData;
            const existingDayIndex = updatedWeeklyDays?.findIndex(
              (day) => day.dayOfWeek === dayOfWeek,
            );

            if (existingDayIndex !== -1) {
              updatedWeeklyDays[existingDayIndex] = {
                ...updatedWeeklyDays[existingDayIndex],
                generalAvailabilityTimings,
              };
            } else {
              updatedWeeklyDays.push({
                id: null,
                dayOfWeek,
                weekNumber: 1,
                generalAvailabilityTimings,
              });
            }
          },
        );

        setWeeklyDays([...updatedWeeklyDays]);
        updateWeeklyDays(updatedWeeklyDays);
      }
    }
  }, [updatedWeekDaysStore]);

  const clearStateData = () => {
    setTimes([{ startTime: DEFAULT_TIME, endTime: DEFAULT_TIME }]);
    setAllDaySwitch(false);
    setNotAvailableSwitch(false);
    updateTotalHours(0);
    setCalculatedHours(0);
    setWeeklyDays([]);
    updateWeeklyDays([]);
    dispatch(updateUpdatedDays([]));
  };

  const copyCurrentAvailabilityCalculateTotalHours = () => {
    let totalHours = 0;

    updatedWeeklyDays?.forEach((day) => {
      if (day?.generalAvailabilityTimings) {
        day?.generalAvailabilityTimings?.forEach((timing: any) => {
          if (timing?.startTimeOffset && timing?.endTimeOffset) {
            if (timing?.allDayAvailable !== true) {
              const start = timing?.startTimeOffset;
              const end = timing?.endTimeOffset;

              if (
                start === END_DATE_INCLUDE_DOT_1_Value &&
                end === END_DATE_INCLUDE_DOT_1_Value
              ) {
                totalHours += 24;
              } else {
                const startTime = new Date(
                  `01/01/2024 ${convertTo24Hour(start)}`,
                );
                let endTime =
                  start !== LAST_SELECTABLE_START_TIME ||
                  (start.includes("AM") &&
                    end.includes("AM") &&
                    end.split(":").map(Number)[0] !== 12)
                    ? new Date(`01/01/2024 ${convertTo24Hour(end)}`)
                    : new Date(`01/02/2024 ${convertTo24Hour(end)}`);

                endTime =
                  end === END_DATE_INCLUDE_DOT_1_Value || end === "00:00:00"
                    ? new Date(`01/02/2024 ${convertTo24Hour(end)}`)
                    : new Date(`01/01/2024 ${convertTo24Hour(end)}`);

                const durationInMinutes =
                  (endTime.getTime() - startTime.getTime()) / (1000 * 60);
                totalHours += durationInMinutes / 60;
              }
            } else {
              if (timing.workingHours) {
                const [workingHours, workingMinutes] = timing.workingHours
                  .split(":")
                  .map(Number);
                const workingHoursInMinutes =
                  workingHours * 60 + workingMinutes;
                totalHours += workingHoursInMinutes / 60;
              }
            }
          }
        });
      }
    });
    updateTotalHours(totalHours);
  };

  const calculateHours = (
    newTimes: { startTime: string; endTime: string }[],
  ) => {
    const totalHours = newTimes?.reduce((acc, time) => {
      const dayTimeObj = { startTime: "00:00 AM", endTime: "00:00 AM" };

      if (allDaySwitch) {
        dayTimeObj.startTime = "00:00 AM";
        dayTimeObj.endTime = "24:00 AM";
      } else {
        dayTimeObj.startTime = time.startTime;
        dayTimeObj.endTime = time.endTime;
      }
      const [startHourStr, startMinuteStr, startSecondStr] = (
        dayTimeObj?.startTime || ""
      ).split(/:|\s/);
      const [endHourStr, endMinuteStr, endSecondStr] = (
        dayTimeObj?.endTime || ""
      ).split(/:|\s/);

      let startHours = parseInt(startHourStr, 10) || 0;
      const startMinutes = parseInt(startMinuteStr, 10) || 0;
      const startSeconds = parseInt(startSecondStr, 10) || 0;
      let endHours = parseInt(endHourStr, 10) || 0;
      const endMinutes = parseInt(endMinuteStr, 10) || 0;
      const endSeconds = parseInt(endSecondStr, 10) || 0;
      let hours;

      if (
        dayTimeObj.startTime?.includes("AM") &&
        startHours === 12 &&
        startMinutes === 0 &&
        dayTimeObj.endTime?.includes("AM") &&
        endHours === 12 &&
        endMinutes === 0
      ) {
        hours = 24;
      } else {
        if (dayTimeObj.startTime?.includes("PM") && startHours !== 12) {
          startHours += 12;
        } else if (dayTimeObj.startTime?.includes("AM") && startHours === 12) {
          startHours = 0;
        }
        if (dayTimeObj.endTime?.includes("PM") && endHours !== 12) {
          endHours += 12;
        } else if (
          dayTimeObj.endTime?.includes("AM") &&
          endHours === 12 &&
          endMinutes === 0
        ) {
          endHours += 12;
        } else if (
          dayTimeObj.endTime?.includes("AM") &&
          endHours === 12 &&
          endMinutes !== 0
        ) {
          endHours = 0;
        }
        if (startHours <= endHours) {
          hours = endHours - startHours;
        } else {
          hours = 24 - startHours + endHours;
        }
      }

      const fractionOfHour =
        (endMinutes - startMinutes) / 60 + (endSeconds - startSeconds) / 3600;
      return acc + hours + fractionOfHour;
    }, 0);

    updateTotalHours(totalHours);
    setCalculatedHours(totalHours);
  };

  const handleAllDaySwitch = () => {
    setAllDaySwitch(!allDaySwitch);
    setNotAvailableSwitch(false);

    const updatedWeeklyDays = [...(weeklyDays || [])];
    const existingDayIndex = updatedWeeklyDays?.findIndex(
      (day) => day.dayOfWeek === dayName?.toUpperCase(),
    );

    if (allDaySwitch) {
      if (existingDayIndex !== -1) {
        updatedWeeklyDays?.splice(existingDayIndex, 1);
      }
    } else {
      if (existingDayIndex !== -1) {
        updatedWeeklyDays[existingDayIndex] = {
          ...updatedWeeklyDays[existingDayIndex],
          generalAvailabilityTimings: [
            {
              startTimeOffset: DEFAULT_TIME_FOR_ALL_DAY_END_DATE,
              endTimeOffset: DEFAULT_TIME_FOR_ALL_DAY,
              workingHours: DEFAULT_HOURS_FOR_ALL_DAY,
              allDayAvailable: true,
            },
          ],
        };
      } else {
        updatedWeeklyDays?.push({
          id: null,
          dayOfWeek: dayName?.toUpperCase(),
          weekNumber: 1,
          generalAvailabilityTimings: [
            {
              startTimeOffset: DEFAULT_TIME_FOR_ALL_DAY_END_DATE,
              endTimeOffset: DEFAULT_TIME_FOR_ALL_DAY,
              workingHours: DEFAULT_HOURS_FOR_ALL_DAY,
              allDayAvailable: true,
            },
          ],
        });
      }
    }

    setWeeklyDays(updatedWeeklyDays);
    updateWeeklyDays(updatedWeeklyDays);
    dispatch(updateUpdatedDays(updatedWeeklyDays));

    const totalHoursBefore = calculateTotalHoursOnSwitch(weeklyDays);
    const totalHoursAfter = calculateTotalHoursOnSwitch(updatedWeeklyDays);
    const totalHoursDifference = totalHoursAfter - totalHoursBefore;

    updateTotalHours(totalHoursDifference);
    setCalculatedHours(totalHoursDifference);
    setTimes([{ startTime: DEFAULT_TIME, endTime: DEFAULT_TIME }]);

    if (!allDaySwitch && !notAvailableSwitch) {
      setDisabledSlots([]);
    }
  };

  const calculateTotalHoursOnSwitch = (days: any[]) => {
    let totalHours = 0;
    days?.forEach((day: { generalAvailabilityTimings: any[] }) => {
      if (day?.generalAvailabilityTimings) {
        day.generalAvailabilityTimings?.forEach((timing: any) => {
          if (timing?.startTimeOffset && timing?.endTimeOffset) {
            if (timing.workingHours) {
              const [workingHours, workingMinutes] = timing.workingHours
                .split(":")
                .map(Number);
              const workingHoursInMinutes = workingHours * 60 + workingMinutes;
              totalHours += workingHoursInMinutes / 60;
            }
          }
        });
      }
    });
    return totalHours;
  };

  const handleNotAvailableSwitch = () => {
    setNotAvailableSwitch(!notAvailableSwitch);
    setAllDaySwitch(false);

    const updatedWeeklyDays = [...(weeklyDays || [])];
    const existingDayIndex = updatedWeeklyDays?.findIndex(
      (day) => day.dayOfWeek === dayName?.toUpperCase(),
    );

    if (notAvailableSwitch) {
      if (existingDayIndex !== -1) {
        updatedWeeklyDays?.splice(existingDayIndex, 1);
      }
    } else {
      if (existingDayIndex !== -1) {
        updatedWeeklyDays[existingDayIndex] = {
          ...updatedWeeklyDays[existingDayIndex],
          generalAvailabilityTimings: null,
          notAvailable: true,
        };
      } else {
        updatedWeeklyDays.push({
          id: null,
          dayOfWeek: dayName?.toUpperCase(),
          weekNumber: 1,
          generalAvailabilityTimings: null,
          notAvailable: true,
        });
      }
    }

    setWeeklyDays(updatedWeeklyDays);
    updateWeeklyDays(updatedWeeklyDays);
    dispatch(updateUpdatedDays(updatedWeeklyDays));

    const totalHoursBefore = calculateTotalHoursOnSwitch(weeklyDays);
    const totalHoursAfter = calculateTotalHoursOnSwitch(updatedWeeklyDays);
    const totalHoursDifference = totalHoursAfter - totalHoursBefore;

    updateTotalHours(totalHoursDifference);
    setCalculatedHours(totalHoursDifference);
    setTimes([{ startTime: DEFAULT_TIME, endTime: DEFAULT_TIME }]);

    if (!allDaySwitch && !notAvailableSwitch) {
      setDisabledSlots([]);
    }
  };

  const updateWeeklyDaysWithNewTimes = (newTimes: any[]) => {
    const updatedDays = [...(weeklyDays || [])];
    const existingDayIndex = updatedDays?.findIndex(
      (day) => day.dayOfWeek === dayName?.toUpperCase(),
    );

    if (existingDayIndex !== -1) {
      const existingDay = updatedDays[existingDayIndex];
      if (existingDay?.generalAvailabilityTimings) {
        const newGeneralAvailabilityTimings = newTimes?.map((time) => ({
          startTimeOffset: time.startTime,
          endTimeOffset: time.endTime,
          workingHours: null,
          allDayAvailable:
            time.startTime &&
            (time.startTime?.includes("1.") || time.endTime?.includes("1.")),
        }));

        const updatedDay = {
          ...existingDay,
          generalAvailabilityTimings: newGeneralAvailabilityTimings,
        };

        updatedDays[existingDayIndex] = updatedDay;
      }
    } else {
      const newDayInfo: WeeklyDay = {
        id: null,
        dayOfWeek: dayName?.toUpperCase(),
        weekNumber: 1,
        generalAvailabilityTimings: newTimes?.map((time) => ({
          startTimeOffset: time.startTime,
          endTimeOffset: time.endTime,
          workingHours: null,
          allDayAvailable:
            time?.startTime &&
            (time?.startTime?.includes("1.") || time.endTime?.includes("1.")),
        })),
      };
      updatedDays?.push(newDayInfo);
    }
    return updatedDays;
  };

  const handleStartTime = (index: number) => (e: any) => {
    const startTime = e?.target?.value;

    setTimes((prevTimes) => {
      const newTimes = [...prevTimes];
      newTimes[index].startTime = startTime;

      const isAllDay =
        startTime &&
        (startTime?.includes("1.") || newTimes[index]?.endTime?.includes("1."));
      setAllDaySwitch(isAllDay);

      const startTime24 = convertTo24Hour(startTime);
      const endTime24 = convertTo24Hour(newTimes[index]?.endTime);

      if (endTime24 && endTime24 <= startTime24) {
        newTimes[index].endTime = add15Minutes(startTime);
      }

      const updatedDays = updateWeeklyDaysWithNewTimes(newTimes);
      setWeeklyDays(updatedDays);
      setUpdatedWeeklyDays(updatedDays);
      copyCurrentAvailabilityCalculateTotalHours();
      calculateHours(newTimes);
      dispatch(updateUpdatedDays(updatedDays));
      return newTimes;
    });
  };

  const handleEndTime = (index: number) => (e: any) => {
    const endTime = e?.target?.value;

    if (!(times[index].startTime === DEFAULT_TIME)) {
      setTimes((prevTimes) => {
        const newTimes = [...prevTimes];
        newTimes[index].endTime = endTime;

        const isAllDay =
          newTimes[index]?.startTime?.includes("1.") || endTime?.includes("1.");
        setAllDaySwitch(isAllDay);

        const startTime24 = convertTo24Hour(newTimes[index]?.startTime);
        const endTime24 =
          endTime === END_DATE_INCLUDE_DOT_1_Value
            ? "24:00"
            : convertTo24Hour(endTime);

        if (
          endTime24 < startTime24 ||
          (endTime > startTime24 &&
            endTime24.split(":").map(Number)[0] === 0 &&
            startTime24.split(":").map(Number)[0] === 0 &&
            endTime24.split(":").map(Number)[1] === 0 &&
            startTime24.split(":").map(Number)[0] !== 0) ||
          endTime24 === startTime24
        ) {
          newTimes[index].endTime = add15Minutes(newTimes[index]?.startTime);
        }

        const updatedDays = updateWeeklyDaysWithNewTimes(newTimes);
        setWeeklyDays(updatedDays);
        setUpdatedWeeklyDays(updatedDays);
        copyCurrentAvailabilityCalculateTotalHours();
        calculateHours(newTimes);
        dispatch(updateUpdatedDays(updatedDays));
        return newTimes;
      });
    } else {
      console.log("Please select a start time before selecting an end time.");
    }
  };

  const add15Minutes = (time: string) => {
    const [hoursStr, minutesStr, period] = time.split(/[:\s]+/);
    let hours = parseInt(hoursStr, 10);
    let minutes = parseInt(minutesStr, 10);

    if (time === START_TIME_NOON) {
      return END_TIME_NOON;
    }

    if (time === LAST_SELECTABLE_START_TIME) {
      return START_TIME;
    }

    minutes += 15;
    if (minutes >= 60) {
      hours += Math?.floor(minutes / 60);
      minutes %= 60;
    }

    hours %= 12;
    if (hours === 0) {
      hours = 12;
    }

    const formattedHours = hours?.toString().padStart(2, "0");
    const formattedMinutes = minutes?.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${period}`;
  };

  const isStartTimeSelected = (index: any) => {
    return !!times[index].startTime && times[index].startTime !== DEFAULT_TIME;
  };

  const addMoreTime = () => {
    const lastEntry = times[times?.length - 1];
    if (
      lastEntry.startTime !== DEFAULT_TIME &&
      lastEntry.endTime !== DEFAULT_TIME
    ) {
      setTimes((prevTimes) => {
        const newTimes = [
          ...prevTimes,
          { startTime: DEFAULT_TIME, endTime: DEFAULT_TIME },
        ];
        setDisabledSlots(
          Array.from({ length: prevTimes?.length }, (_, index) => index),
        );
        return newTimes;
      });
    } else {
      console.log("Please select both start and end times before adding more.");
    }
  };

  const removeTime = (index: number) => {
    setTimes((prevTimes) => {
      const newTimes = [...prevTimes];
      newTimes?.splice(index, 1);

      const updatedDays = updateWeeklyDaysWithNewTimes(newTimes);
      dispatch(updateUpdatedDays(updatedDays));
      setUpdatedWeeklyDays(updatedDays);
      setWeeklyDays(updatedDays);
      setDisabledSlots(() => {
        return Array?.from({ length: newTimes?.length - 1 }, (_, i) => i);
      });

      calculateHours(newTimes);
      if (index < newTimes?.length) {
        setDisabledSlots(() => {
          const updatedDisabledSlots = Array?.from(
            { length: newTimes?.length },
            (_, i) => i,
          );
          updatedDisabledSlots?.splice(-1, 1);
          return updatedDisabledSlots;
        });
      }
      return newTimes;
    });
  };

  const formatTime = (timeString: any) => {
    if (!/[ap]m/i?.test(timeString)) {
      const [hours, minutes] = timeString.split(":");
      const hoursInt = parseInt(hours, 10);
      const suffix = hoursInt >= 12 ? "PM" : "AM";
      const formattedHours = hoursInt % 12 || 12;
      return `${formattedHours}:${minutes} ${suffix}`;
    }
    return timeString;
  };

  const getMinutes = (time: string) => {
    const parts = time?.split(":");
    if (parts?.length >= 2) {
      return parseInt(parts[1]);
    } else {
      analytics?.trackEvent({
        name: `Error: Invalid time format`,
        properties: {
          page: "Availability",
          workflow: "Create",
          component: "MarkDay",
        },
      });
      throw new Error("Invalid time format");
    }
  };

  const getStoreHours = () => {
    if (!storeHoursSuccess) return translate("STORE_HOURS_UNAVAILABLE");

    const hoursData = storeOperatingHours?.find(
      (data: any) => data?.dayOfWeek === dayName?.toUpperCase(),
    );

    if (!hoursData) return translate("STORE_HOURS_UNAVAILABLE");
    if (hoursData.openToBusiness === false)
      return translate("STORE_HOURS_CLOSED");

    return `${translate("STORE_HOURS", { open: formatTime(hoursData.staffOpenTime), close: formatTime(hoursData.staffCloseTime) })}`;
  };

  return (
    <Grid
      item
      xs={12}
      container
      className={
        allDaySwitch ||
        notAvailableSwitch ||
        copyCurrentAvailability ||
        editPendingAvailability ||
        isStartTimeSelected(0)
          ? "day-wrapper active"
          : "day-wrapper"
      }
      direction={"row"}
    >
      <Stack
        className={"day-item"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <TypoGraphy variant="body1" typeClass={"semi-bold-font"}>
          {translate(dayName)?.substring(0, 3)?.toUpperCase()}
        </TypoGraphy>
      </Stack>
      <Stack direction={"column"} className={"day-action"} spacing={1}>
        <Grid xs={12} item textAlign={"right"}>
          <TypoGraphy variant="body1" typeClass={"regular-font day-hours"}>
            {`${calculatedHours} ${translate("HRS")}`}
          </TypoGraphy>
        </Grid>
        <Grid container direction={"row"} className={"switch-wrapper"}>
          <Grid item xs={5} md={4}>
            <CustomSwitch
              handleSwitch={() => {
                handleAllDaySwitch();
              }}
              label={translate("ALL_DAY")}
              variant={"body1"}
              checked={allDaySwitch}
              typeClass="switch-label"
            />
          </Grid>
          <Grid item xs={7} md={8}>
            <CustomSwitch
              handleSwitch={handleNotAvailableSwitch}
              label={translate("NOT_AVAILABLE")}
              variant={"body1"}
              checked={notAvailableSwitch}
              typeClass="switch-label"
            />
          </Grid>
        </Grid>
        {!allDaySwitch &&
          !notAvailableSwitch &&
          times?.map((time, index) => (
            <Grid
              container
              direction={"row"}
              className={"time-wrapper"}
              alignItems={"center"}
              key={index}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                className="time-section"
              >
                <Stack className="timer-picker">
                  <Select
                    value={time.startTime}
                    onChange={handleStartTime(index)}
                    name={`StartTime-${index}`}
                    renderValue={(selected) => {
                      if (selected === DEFAULT_TIME) {
                        return translate("START_TIME");
                      } else {
                        return formatTime(selected);
                      }
                    }}
                    disabled={
                      allDaySwitch ||
                      notAvailableSwitch ||
                      disabledSlots?.includes(index)
                    }
                  >
                    {(copyCurrentAvailability && time.startTime) ||
                    (editPendingAvailability && time.startTime)
                      ? startTimeArr
                          ?.filter((item) => {
                            if (index === 0) {
                              return true;
                            } else {
                              const endTime24 = parseInt(
                                convertTo24Hour(times[index - 1]?.endTime),
                              );
                              const endTimeMinutes = getMinutes(
                                times[index - 1]?.endTime,
                              );
                              const startTime24 = parseInt(
                                convertTo24Hour(item),
                              );
                              const startTimeMinutes = getMinutes(item);
                              return !(
                                endTime24 * 60 + endTimeMinutes >=
                                startTime24 * 60 + startTimeMinutes
                              );
                            }
                          })
                          .map((item) => {
                            const displayTime = item;
                            return (
                              <MenuItem key={item} value={item}>
                                {displayTime}
                              </MenuItem>
                            );
                          })
                      : startTimeArr
                          ?.filter((item) => {
                            if (index === 0) {
                              return true;
                            } else {
                              const endTime24 = parseInt(
                                convertTo24Hour(times[index - 1].endTime),
                              );
                              const endTimeMinutes = getMinutes(
                                times[index - 1].endTime,
                              );
                              const startTime24 = parseInt(
                                convertTo24Hour(item),
                              );
                              const startTimeMinutes = getMinutes(item);
                              return !(
                                endTime24 * 60 + endTimeMinutes >=
                                startTime24 * 60 + startTimeMinutes
                              );
                            }
                          })
                          .map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                  </Select>
                </Stack>
                <Stack className={"time-divider"}></Stack>
                <Stack className="timer-picker">
                  <Select
                    value={time.endTime}
                    onChange={handleEndTime(index)}
                    name={`EndTime-${index}`}
                    renderValue={(selected) => {
                      if (selected === DEFAULT_TIME) {
                        return translate("END_TIME");
                      } else {
                        return formatTime(selected);
                      }
                    }}
                    disabled={
                      allDaySwitch ||
                      notAvailableSwitch ||
                      disabledSlots?.includes(index)
                    }
                  >
                    {(copyCurrentAvailability && time.endTime) ||
                    (editPendingAvailability && time.endTime)
                      ? endTimeArr
                          ?.filter((item) => {
                            const startTime24 = parseInt(
                              convertTo24Hour(times[index]?.startTime),
                            );
                            const startTimeMinutes = getMinutes(
                              times[index]?.startTime,
                            );
                            const endTime24 = parseInt(
                              convertTo24HourEndTime(item),
                            );
                            const endTimeMinutes = getMinutes(item);

                            function getMinutes(startTime: string) {
                              return parseInt(startTime?.split(":")[1]);
                            }
                            return !(
                              times?.some(
                                (t, i) => i !== index && t.endTime === item,
                              ) ||
                              (times?.[index].startTime &&
                                startTime24 * 60 + startTimeMinutes >=
                                  endTime24 * 60 + endTimeMinutes)
                            );
                          })
                          .map((item) => {
                            const displayTime = item;
                            return (
                              <MenuItem key={item} value={item}>
                                {displayTime}
                              </MenuItem>
                            );
                          })
                      : endTimeArr
                          ?.filter((item) => {
                            const startTime24 = parseInt(
                              convertTo24Hour(times[index].startTime),
                            );
                            const startTimeMinutes = getMinutes(
                              times[index].startTime,
                            );
                            const endTime24 = parseInt(
                              convertTo24HourEndTime(item),
                            );
                            const endTimeMinutes = getMinutes(item);

                            return !(
                              times?.some(
                                (t, i) => i !== index && t.endTime === item,
                              ) ||
                              (times?.[index].startTime &&
                                startTime24 * 60 + startTimeMinutes >=
                                  endTime24 * 60 + endTimeMinutes)
                            );
                          })
                          .map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                  </Select>
                </Stack>
              </Stack>
              <Stack>
                {index > 0 && (
                  <CustomIconBtn
                    IconComponent={RemoveCircleOutline}
                    iconClass={"remove-time"}
                    onClick={() => {
                      analytics?.trackEvent({
                        name: `Remove time`,
                        properties: {
                          page: "Availability",
                          workflow: "Create",
                          component: "Mark Day",
                        },
                      });
                      removeTime(index);
                    }}
                  />
                )}
              </Stack>
            </Grid>
          ))}
        {!allDaySwitch &&
          !notAvailableSwitch &&
          times?.length < 5 &&
          isStartTimeSelected(times?.length - 1) && (
            <Grid xs={12} item textAlign={"right"}>
              {times?.length > 0 &&
                times[times?.length - 1].startTime !==
                  LAST_SELECTABLE_START_TIME &&
                times[times?.length - 1].endTime !==
                  LAST_SELECTABLE_START_TIME &&
                times[times?.length - 1].endTime !== LAST_SELECTABLE_END_TIME &&
                times[times?.length - 1].endTime !==
                  END_DATE_INCLUDE_DOT_1_Value &&
                times[times?.length - 1].endTime !== "00:00:00" && (
                  <CustomIconBtn
                    IconComponent={AddCircleOutline}
                    iconClass={"add-more-time"}
                    onClick={() => {
                      analytics?.trackEvent({
                        name: `Add more time`,
                        properties: {
                          page: "Availability",
                          workflow: "Create",
                          component: "Mark Day",
                        },
                      });
                      addMoreTime();
                    }}
                  />
                )}
            </Grid>
          )}
        <TypoGraphy typeClass={"light-font"}>{getStoreHours()}</TypoGraphy>
      </Stack>
    </Grid>
  );
};

export default MarkDay;
