import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import RequestCondition from "./RequestCondition";
import RequestWrapper from "./RequestWrapper";
import { setCopyCurrentAvailability } from "../../../../features/copyAvailabilityReducer";

interface Props {
  comment: string;
  setComment: (comment: string) => void;
  weeklyDays: any;
  setWeeklyDays: (weeklyDays: any) => void;
  totalHours: any;
  setTotalHours: (totalHours: any) => void;
}

const WeeklyAvailabilityRequest: React.FC<Props> = ({
  comment,
  setComment,
  weeklyDays,
  setWeeklyDays,
  totalHours,
  setTotalHours,
}) => {
  const dispatch = useAppDispatch();
  const { storeData } = useAppSelector((state: any) => state?.userStoreData);

  const handleCopyRequest = (checked: boolean) => {
    dispatch(setCopyCurrentAvailability(checked));
  };

  const calculateTotalHours = (timeString: string | number): number => {
    if (typeof timeString === "number" || typeof timeString === "undefined") {
      return Number(timeString);
    } else {
      const [hours, minutes] = timeString.split(":").map(Number);
      return hours + (minutes || 0) / 60;
    }
  };
  const adjustedTotalHours = totalHours;
  const totalHoursNumber = calculateTotalHours(adjustedTotalHours);

  return (
    <>
      {totalHoursNumber < storeData.minAvailabilityHours && (
        <RequestCondition />
      )}
      <RequestWrapper
        handleCopyRequest={handleCopyRequest}
        comment={comment}
        setComment={setComment}
        weeklyDays={weeklyDays}
        setWeeklyDays={setWeeklyDays}
        updateComment={setComment}
        totalHours={totalHours}
        setTotalHours={setTotalHours}
      />
    </>
  );
};

export default WeeklyAvailabilityRequest;
