import { handleFetchError } from "../../utils/errorHandler";

interface HttpClient {
  get(url: string): Promise<Response>;
}

class UserStoreService {
  private httpClient: HttpClient;
  private url: string;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
    this.url = "/user/user-profile";
  }

  async getData(userId: any): Promise<any> {
    try {
      const response = await this.httpClient.get(
        this.url + `?userId=${userId}`,
      );

      if (response.status === 200) {
        const userData = await response.json();
        return userData;
      } else {
        return response;
      }
    } catch (error: any) {
      const errorMessage = handleFetchError(error);
      console.error("API Error - User Profile error:", errorMessage);
      return null;
    }
  }

  async getStoreHours(input: {
    date: string;
    locationID: string;
  }): Promise<any> {
    try {
      const response = await this.httpClient.get(
        `/location/${input.locationID}/hoo/${input.date}`,
      );

      if (response.status === 200) {
        const storeHours = await response.json();
        return storeHours;
      } else {
        return response;
      }
    } catch (error: any) {
      const errorMessage = handleFetchError(error);
      console.error("API Error - Store Hours error:", errorMessage);
      return null;
    }
  }
}

export default UserStoreService;
