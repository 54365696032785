import React, { useEffect, useRef, useState } from "react";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import TypoGraphy from "../../../common/Typography";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import moment from "moment";

interface Props {
  startDate?: string;
  onClick: (event: React.KeyboardEvent | React.MouseEvent) => void;
  endDate?: string;
  isStartDateExist?: boolean;
}

const DateHeader: React.FC<Props> = ({
  startDate,
  onClick,
  endDate,
  isStartDateExist,
}) => {
  const { t: translate, i18n } = useTranslation();
  const startDateRef = useRef<any>(null);
  const endDateRef = useRef<any>(null);
  const [startWidth, setStartWidth] = useState<number>(0);
  const [endWidth, setEndWidth] = useState<number>(0);
  const [dotWidth, setDotWidth] = useState<string>("");
  dayjs.locale(i18n.language);
  dayjs.extend(updateLocale);

  useEffect(() => {
    if (startDateRef.current) {
      setStartWidth(startDateRef.current.offsetWidth);
    }

    if (endDateRef.current) {
      setEndWidth(endDateRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (startWidth && endWidth) {
      const width = `calc(100% - ${startWidth + endWidth}px)`;
      setDotWidth(width);
    }
  }, [startWidth, endWidth]);

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      className={isStartDateExist ? "date-header disable" : "date-header"}
      onClick={onClick}
    >
      <Stack ref={startDateRef} direction={"row"}>
        <TypoGraphy
          typeClass="light-font"
          variant="body1"
          aria-label={translate("START")}
        >
          {translate("START")}:
        </TypoGraphy>
        <TypoGraphy
          variant="body1"
          typeClass="semi-bold-font"
          aria-label={
            startDate &&
            moment
              .utc(startDate)
              ?.format(i18n.language === "fr" ? "YYYY-MM-DD" : "MMM DD, YYYY")
          }
        >
          {startDate && isStartDateExist
            ? moment
                .utc(startDate)
                ?.format(i18n.language === "fr" ? "YYYY-MM-DD" : "MMM DD, YYYY")
            : startDate &&
              !isStartDateExist &&
              dayjs(startDate)?.format(
                i18n.language === "fr" ? "YYYY-MM-DD" : "MMM DD, YYYY",
              )}
        </TypoGraphy>
      </Stack>

      <Stack
        direction={"row"}
        style={{ width: dotWidth }}
        className="dot-stack"
      ></Stack>

      <Stack direction={"row"} ref={endDateRef}>
        <TypoGraphy
          typeClass="light-font"
          variant="body1"
          fontWeight="bold"
          aria-label={translate("END")}
        >
          {translate("END")}:
        </TypoGraphy>
        <TypoGraphy
          variant="body1"
          typeClass="semi-bold-font"
          aria-label={
            endDate
              ? moment
                  .utc(endDate)
                  ?.format(
                    i18n.language === "fr" ? "YYYY-MM-DD" : "MMM DD, YYYY",
                  )
              : translate("NO_END_DATE")
          }
        >
          {endDate
            ? moment
                .utc(endDate)
                ?.format(i18n.language === "fr" ? "YYYY-MM-DD" : "MMM DD, YYYY")
            : translate("NO_END_DATE")}
        </TypoGraphy>
      </Stack>
    </Stack>
  );
};

export default DateHeader;
