import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updatedDays: [],
  editNewAvailabilityRequest: false,
};

const updatedDaysSlice = createSlice({
  name: "updatedDays",
  initialState,
  reducers: {
    updateUpdatedDays(state, action) {
      state.updatedDays = action.payload;
    },
    editNewAvailabilityRequest(state, action) {
      state.editNewAvailabilityRequest = action.payload;
    },
  },
});

export const { updateUpdatedDays, editNewAvailabilityRequest } =
  updatedDaysSlice.actions;

export default updatedDaysSlice.reducer;
