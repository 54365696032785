import { createSlice } from "@reduxjs/toolkit";
import { getAvailabilityAll } from "./action";

export interface AvailableAllState {
  availabilityAllData: any;
  availabilityData: any;
  availabilityRequestData: any;
  loading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: AvailableAllState = {
  availabilityAllData: [],
  availabilityData: [],
  availabilityRequestData: [],
  loading: false,
  error: null,
  success: false,
};

export const availabilityAllSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    UPDATE_AVAILABLE_REQUEST: (state, action) => {
      state.availabilityRequestData = action.payload;
    },
    DELETE_FUTURE_AVAILABILITY: (state, action) => {
      state.availabilityRequestData = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAvailabilityAll.pending, (state: AvailableAllState) => {
        state.loading = true;
        state.success = false;
      })

      .addCase(
        getAvailabilityAll.fulfilled,
        (state: AvailableAllState, action: any) => {
          state.loading = false;
          state.availabilityAllData = action.payload.data;
          state.availabilityData = action.payload?.data?.availabilityResponse;
          state.availabilityRequestData =
            action.payload?.data?.availabilityRequestResponse;
          state.success = true;
        },
      )
      .addCase(
        getAvailabilityAll.rejected,
        (state: AvailableAllState, action: any) => {
          state.loading = false;
          state.error = action.payload;
          state.success = false;
        },
      );
  },
});

export default availabilityAllSlice.reducer;
