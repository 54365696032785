import { createSlice } from "@reduxjs/toolkit";

const copyAvailabilitySlice = createSlice({
  name: "availability",
  initialState: {
    copyCurrentAvailability: false,
  },
  reducers: {
    setCopyCurrentAvailability(state, action) {
      state.copyCurrentAvailability = action.payload;
    },
  },
});

export const { setCopyCurrentAvailability } = copyAvailabilitySlice.actions;
export default copyAvailabilitySlice.reducer;
