import { Stack } from "@mui/material";
import TypoGraphy from "../../common/Typography";

interface Props {
  className?: string;
  message?: string;
}

const NoAvailability: React.FC<Props> = ({ className, message }) => {
  return (
    <Stack className={className} textAlign={"center"}>
      <TypoGraphy variant="body1" typeClass="regular-font primary-text-hover">
        {message}
      </TypoGraphy>
    </Stack>
  );
};

export default NoAvailability;
