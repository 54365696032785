import { createSlice } from "@reduxjs/toolkit";
import { getAvailability } from "./action";

export interface AvailableState {
  availabilityData: any;
  loading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: AvailableState = {
  availabilityData: [],
  loading: false,
  error: null,
  success: false,
};

export const availabilitySlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAvailability.pending, (state: AvailableState) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(
        getAvailability.fulfilled,
        (state: AvailableState, action: any) => {
          state.loading = false;
          state.availabilityData = action.payload;
          state.success = true;
        },
      )
      .addCase(
        getAvailability.rejected,
        (state: AvailableState, action: any) => {
          state.loading = false;
          state.error = action.payload;
          state.success = false;
        },
      );
  },
});

export default availabilitySlice.reducer;
