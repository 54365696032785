import React from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";

interface Props extends ButtonProps {
  text?: string;
  variant?: "text" | "outlined" | "contained";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  onClick?: (event: React.KeyboardEvent | React.MouseEvent) => void;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  btnClass?: string;
  circularProgressClass?: string;
  loading?: boolean;
}

const CustomBtn: React.FC<Props> = ({
  text,
  variant,
  color,
  onClick,
  startIcon,
  btnClass,
  circularProgressClass,
  endIcon,
  loading,
  ...rest
}) => {
  return (
    <>
      {!loading && (
        <Button
          variant={variant}
          color={color}
          className={btnClass}
          onClick={onClick}
          startIcon={startIcon}
          endIcon={endIcon}
          {...rest}
        >
          {text}
        </Button>
      )}
      {loading && (
        <Button
          variant={variant}
          color={color}
          className={btnClass}
          onClick={onClick}
          startIcon={startIcon}
          endIcon={endIcon}
          {...rest}
        >
          <CircularProgress
            color="success"
            size="1rem"
            className={circularProgressClass}
          />
          {text}
        </Button>
      )}
    </>
  );
};

export default CustomBtn;
