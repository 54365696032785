import { FormControl, Grid, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import TypoGraphy from "../../../common/Typography";
import { PreferenceType } from "../constants";
interface Props {
  handleChange?: any;
  label?: string;
  value?: number;
  name?: string;
  itemClass?: string;
  hourList?: any[] | undefined;
  activePreference?: PreferenceType;
  ariaLabel?: string;
}

const EditHours: React.FC<Props> = ({
  label,
  handleChange,
  value,
  name,
  itemClass,
  hourList,
  ariaLabel,
}) => {
  const { t: translate } = useTranslation();
  return (
    <>
      <Grid item xs={4} container className={itemClass} direction={"column"}>
        <TypoGraphy typeClass={"semi-bold-font primary-text"} variant={"body1"}>
          {label}
        </TypoGraphy>
        <FormControl fullWidth>
          <Select
            value={value}
            onChange={handleChange}
            inputProps={{ "aria-label": `${ariaLabel}` }}
            name={name}
            tabIndex={0}
          >
            {hourList?.map((item: any) => (
              <MenuItem key={item.value} value={item.value}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TypoGraphy typeClass="semi-bold-font" variant={"body1"}>
          {translate("HRS")}/{translate("WEEK")}
        </TypoGraphy>
      </Grid>
    </>
  );
};

export default EditHours;
