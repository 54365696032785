import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import availabilityReducer from "../../features/availabilityReducer";
import availabilityAllReducer from "../../features/availabilityAllReducer";
import availableRequestReducer from "../../features/availabilityRequestReducer";
import preferencesReducer, {
  preferencesAttributeSlice,
} from "../../features/preferencesReducer";
import authDetailsReducer from "../../features/authDetailsReducer";
import userStoreReducer, {
  userStoreHoursSlice,
} from "../../features/userStoreReducer";
import copyAvailabilitySlice from "../../features/copyAvailabilityReducer";
import weekdaysReducer from "../../features/weekdaysReducer";
import { metaSlice } from "../../features/metaReducer";

export const store = configureStore({
  reducer: {
    availability: availabilityReducer,
    availabilityAll: availabilityAllReducer,
    availableRequest: availableRequestReducer,
    preferences: preferencesReducer,
    preferencesAttribute: preferencesAttributeSlice.reducer,
    authDetails: authDetailsReducer,
    storeHoursData: userStoreHoursSlice.reducer,
    userStoreData: userStoreReducer,
    copyCurrentAvailability: copyAvailabilitySlice,
    updateWeekdays: weekdaysReducer,
    meta: metaSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = useDispatch as () => typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
