import { Stack } from "@mui/material";
import React from "react";
import TypoGraphy from "../../../common/Typography";
import { CommentType } from "../constants";
import { useTranslation } from "react-i18next";
import { formatDateStringWithoutToday } from "../../../../utils/durationCalculate";
import { getMonthTranslations } from "../../../../utils/getDays";

interface Props {
  CommentData?: CommentType;
  CommentClass?: string;
}

const CommentCard: React.FC<Props> = ({ CommentData, CommentClass }) => {
  const { t: translate, i18n } = useTranslation();
  const { isToday, getMonthTranslation } = getMonthTranslations();
  return (
    <Stack className={CommentClass}>
      <Stack>
        <TypoGraphy variant="body1" typeClass={"regular-font"}>
          {CommentData?.comment}
        </TypoGraphy>
      </Stack>
      <Stack textAlign={"right"}>
        <TypoGraphy variant="body2" typeClass={"light-font"}>
          {" "}
          {formatDateStringWithoutToday(
            CommentData?.requestLastUpdatedDate
              ? CommentData?.requestLastUpdatedDate
              : "",
            isToday,
            getMonthTranslation,
            translate,
            i18n.language,
          )}
        </TypoGraphy>
      </Stack>
    </Stack>
  );
};

export default CommentCard;
