import { Grid } from "@mui/material";
import KickOffHeader from "./KickOffHeader";
import DateHeader from "../../shared/DateHeader/DateHeader";

interface Props {
  startDate?: string;
  endDate?: string;
  averageHrs?: number | string;
  current?: boolean;
  weekNumber?: number;
}

const AccHeader: React.FC<Props> = ({
  startDate,
  endDate,
  averageHrs,
  current,
  weekNumber,
}) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <DateHeader startDate={startDate} endDate={endDate} current={current} />
      </Grid>
      <Grid container item xs={12} className={"kick-status"}>
        <KickOffHeader
          startDate={startDate}
          averageHrs={averageHrs}
          weekNumber={weekNumber}
          current={current}
        />
      </Grid>
    </Grid>
  );
};

export default AccHeader;
