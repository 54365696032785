import { Button, Stack, TextField } from "@mui/material";
import "./styles.scss";
import { useContext } from "react";
import { AnalyticsContext } from "../../../analytics";

interface Props {
  handleChange?: any;
  submitEmpId?: () => void;
  empID?: any;
  empError?: boolean;
}

const Login: React.FC<Props> = ({
  handleChange,
  submitEmpId,
  empError,
  empID,
}) => {
  const analytics = useContext(AnalyticsContext);

  return (
    <Stack className="login-overlay">
      <Stack className="login-modal">
        <h2>Employee ID</h2>
        <Stack>
          <TextField
            label="Enter Here"
            variant="outlined"
            onChange={handleChange}
            value={empID}
          />
        </Stack>
        {empError && (
          <Stack className="error">Please enter a valid Employee ID</Stack>
        )}
        <Stack>
          <Button
            className="secondary-btn"
            onClick={() => {
              analytics?.trackEvent({
                name: `Login button click`,
                properties: {
                  page: "Login",
                  workflow: "View",
                  component: "Login button",
                },
              });
              submitEmpId && submitEmpId();
            }}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Login;
